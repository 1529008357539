import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register the necessary components for the Pie chart
ChartJS.register(ArcElement, Tooltip, Legend);

const ChartPie = ({ data, labels, colors, height, width, setIsPaidEmployee, setShowModalPayment }) => {
  // Define the chart data and default values
  const chartData = {
    datasets: [
      {
        data: data || [0, 0], // Default to [0, 0] if no data is passed
        borderWidth: 0,
        backgroundColor: colors || ["#496ecc", "#68e365"], // Default colors
        hoverBackgroundColor: colors || ["#496ecc", "#68e365"], // Default hover colors
      },
    ],
    labels: labels || ["Label1", "Label2"], // Default labels
  };

  // Define the chart options
  const options = {
    responsive: true,
    onClick: (e, elements) => {
      if (elements.length > 0) {
        // The index of the clicked slice
        setShowModalPayment(true)
        const index = elements[0].index;
        if (index === 1) {
          setIsPaidEmployee('Unpaid');
        } else {
          setIsPaidEmployee('Paid');
        }
      }},
    plugins: {
      legend: {
        display: false, // Hide the legend
        position: "bottom",
      },
    },
    maintainAspectRatio: false, // Maintain aspect ratio of the chart
  };

  return (
    <Pie
      data={chartData}
      height={height || 200} // Default height if not provided
      width={width || 200}   // Default width if not provided
      options={options}
    />
  );
};

export default ChartPie;