import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { getDecodedRefreshTokenFromLocalStorage } from "../../../jwt/jwtDecoder";
import { useLanguage } from "../../../context/LanguageContext";
import translations from "../../../translation/translation";
const AddEmployee = () => {
    const parser = JSON.parse(localStorage.getItem("userDetails"));
    const token = parser.access;
    const history = useNavigate();
    const { language } = useLanguage();
    const t = translations[language];
    const decodedToken = getDecodedRefreshTokenFromLocalStorage("userDetails");
    const userID = decodedToken.payload.user_id;
  const [isLoading, setIsLoading] = useState(false);
    const initialProjectState = {
      ProjectID_1C: "",
      ProjectCode: "",
      ProjectNameEN: "",
      ProjectNameRU: "",
      ProjectNameTR: "",
      AddressEN: "",
      AddressRU: "",
      AddressTR: "",
      StartDate: "",
      EndDate: "",
      Author: userID,
      SubjectofRF: "",
    };
    const [newProject, setNewProject] = useState(initialProjectState);
    const [subjectOptions, setSubjectOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const urlLink = process.env.REACT_APP_API_URL;
  
    const handleBack = () => {
        history(-1);
      };
    
    const fetchSubjects = async () => {
      try {
        const response = await fetch(`${urlLink}/gendt/subject-rf/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(t.failedtofetchsubjects);
        }
        const data = await response.json();
        setSubjectOptions(data.Response);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };
  
    useEffect(() => {
      fetchSubjects();
    }, []);

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setNewProject((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleAddProject = (event) => {
      event.preventDefault();
      newProject[`SubjectofRF`] = subjectOptions.find(a => a[`SubjectOfRF_${language.toUpperCase()}`].toLowerCase() == searchCountryInputPost?.trim().toLowerCase())?.id || swal(t.error, "", "error")
      const formattedProject = {
        ...newProject,
        StartDate: formatDate(newProject.StartDate),
        EndDate: formatDate(newProject.EndDate),
      };
      const url = `${urlLink}/gendt/project/`;
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedProject),
      };
  
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(t.failedtocreatenewproject);
          }
          return response.json();
        })
        .then((data) => {
          const savedData = data["Data is successfully saved"];
  
          swal(
            t.success.charAt(0).toUpperCase() + t.success.slice(1),
            t.projectaddedsuccessfully,
            "success"
          );
          handleBack()
        })
        .catch((error) => {
          swal(
            t.error.charAt(0).toUpperCase() + t.error.slice(1),
            t.failedtoaddproject + error.message,
            "error"
          );
        });
    };
  
    const formatDate = (date) => {
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    };
    const [searchCountryInputPost, setSearchCountryInputPost] = useState('')
    const [countriesDataPost, setCountriesDataPost] = useState(subjectOptions || [])
    const [countrySearchBoxStatePost, setCountrySearchBoxStatePost] = useState(false)
    const [countryStyleIndexPost, setCountryStyleIndexPost] = useState(null)
  
    useEffect(() => {
      setCountriesDataPost(subjectOptions.filter(e=>e[`SubjectOfRF_${language.toUpperCase()}`].slice(0, searchCountryInputPost?.length).toLowerCase() == searchCountryInputPost?.trim().toLowerCase()))
      const find = subjectOptions.find(e => searchCountryInputPost == e[`SubjectOfRF_${language.toUpperCase()}`])
      if(find) setCountryStyleIndexPost(find.id)
    }, [searchCountryInputPost])
    useEffect(() => {
      setCountriesDataPost(subjectOptions)
    }, [countrySearchBoxStatePost])
  return (
    <div className="addemployee__wrapper" onClick={e => {
        if(e.target.dataset.employeeSearch != "search"){
          setCountrySearchBoxStatePost(false)
        }
      }}>
      {isLoading && (
        <div className="fullscreen-overlay">
          <div className="spinner-container">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading...</p>
          </div>
        </div>
      )}
      <Form onSubmit={handleAddProject}>
      <div className="addemployee-input-wrapper">
            <Form.Group className="employee-fill-wrapper" controlId="ProjectID_1C">
              <Form.Label className="input__label">{t.projectid}</Form.Label>
              <Form.Control
                type="text"
                className="edit__input employee-fill-wrapper-input"
                // placeholder={t.enterprojectid}
                name="ProjectID_1C"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                value={newProject.ProjectID_1C}
                onChange={handleInputChange}
                placeholder={t.enterprojectid}
              />
            </Form.Group>
            <Form.Group className="employee-fill-wrapper" controlId="ProjectCode">
              <Form.Label className="input__label">{t.projectcode}</Form.Label>
              <Form.Control
                type="text"
                className="edit__input employee-fill-wrapper-input"
                // placeholder={t.enterprojectcode}
                name="ProjectCode"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                value={newProject.ProjectCode}
                onChange={handleInputChange}
                placeholder={t.enterprojectcode}
              />
            </Form.Group>
            </div>
            <div className="addemployee-input-wrapper">
            <Form.Group className="employee-fill-wrapper" controlId="ProjectNameEN">
              <Form.Label className="input__label">{t.projectName} (EN)</Form.Label>
              <Form.Control
                type="text"
                // placeholder={t.enterenglishprojectname}
                name="ProjectNameEN"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                value={newProject.ProjectNameEN}
                onChange={handleInputChange}
                className="edit__input employee-fill-wrapper-input"
                placeholder={t.enterenglishprojectname}
              />
            </Form.Group>
            <Form.Group className="employee-fill-wrapper" controlId="ProjectNameRU">
              <Form.Label className="input__label">{t.projectName} (RU)</Form.Label>
              <Form.Control
                type="text"
                className="edit__input employee-fill-wrapper-input"
                // placeholder={t.enterrussianprojectname}
                name="ProjectNameRU"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                value={newProject.ProjectNameRU}
                onChange={handleInputChange}
                placeholder={t.enterrussianprojectname}
              />
            </Form.Group>
            </div>
            <div className="addemployee-input-wrapper">
            <Form.Group className="employee-fill-wrapper" controlId="ProjectNameTR">
              <Form.Label className="input__label">{t.projectName} (TR)</Form.Label>
              <Form.Control
                type="text"
                className="edit__input employee-fill-wrapper-input"
                // placeholder={t.enterturkishprojectname}
                name="ProjectNameTR"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                value={newProject.ProjectNameTR}
                onChange={handleInputChange}
                placeholder={t.enterturkishprojectname}
              />
            </Form.Group>
            <Form.Group className="employee-fill-wrapper" controlId="AddressEN">
              <Form.Label className="input__label">{t.address} (EN)</Form.Label>
              <Form.Control
                type="text"
                className="edit__input employee-fill-wrapper-input"
                // placeholder={t.enterenglishaddress}
                name="AddressEN"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                value={newProject.AddressEN}
                onChange={handleInputChange}
                placeholder={t.enterenglishaddress}
              />
            </Form.Group>
            </div>
            <div className="addemployee-input-wrapper">
            <Form.Group className="employee-fill-wrapper" controlId="AddressRU">
              <Form.Label className="input__label">{t.address} (RU)</Form.Label>
              <Form.Control
                type="text"
                className="edit__input employee-fill-wrapper-input"
                // placeholder={t.enterrussianaddress}
                name="AddressRU"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                value={newProject.AddressRU}
                onChange={handleInputChange}
                placeholder={t.enterrussianaddress}
              />
            </Form.Group>
            <Form.Group className="employee-fill-wrapper" controlId="AddressTR">
              <Form.Label className="input__label">{t.address} (TR)</Form.Label>
              <Form.Control
                type="text"
                className="edit__input employee-fill-wrapper-input"
                // placeholder={t.enterturkishaddress}
                name="AddressTR"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                value={newProject.AddressTR}
                onChange={handleInputChange}
                placeholder={t.enterturkishaddress}
              />
            </Form.Group>
            </div>
            <div className="addemployee-input-wrapper">
            <Form.Group className="employee-fill-wrapper" controlId="StartDate">
              <Form.Label className="input__label">{t.startDate}</Form.Label>
              <Form.Control
                type="date"
                className="edit__input employee-fill-wrapper-input"
                name="StartDate"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                defaultValue={newProject.StartDate}
                onChange={handleInputChange}
                max={newProject.EndDate || ""}
              />
            </Form.Group>
            <Form.Group className="employee-fill-wrapper" controlId="EndDate">
              <Form.Label className="input__label">{t.endDate}</Form.Label>
              <Form.Control
                type="date"
                className="edit__input employee-fill-wrapper-input"
                name="EndDate"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                defaultValue={newProject.EndDate}
                onChange={handleInputChange}
                min={newProject.StartDate || ""}
              />
            </Form.Group>
            </div>
            <Form.Group controlId="formSubjectofRF">
              <Form.Label className="input__label">{t.subjectofrf}</Form.Label>
              <Form.Control data-employee-search="search"
                type="text"
                className="edit__input employee-fill-wrapper-input"
                name="SubjectofRF"
                style={{
                  border: "1px solid #5bcfc5",
                }}
                value={searchCountryInputPost}
                onInput={e => {
                  setSearchCountryInputPost(e.target.value)
                  setCountrySearchBoxStatePost(true)
                }}
                onClick={() => {
                  setCountrySearchBoxStatePost(true)
                }}
                placeholder={t.subjectofrf}
              />
                {countrySearchBoxStatePost && <div data-employee-search="search" className="countries_search__wrapper">
              <div className="countries_container">
              {countriesDataPost.map((e) => 
             <div onMouseOver={() => {
              setCountryStyleIndexPost(e.id)
            }} onClick={() => {
             setSearchCountryInputPost(e[`SubjectOfRF_${language.toUpperCase()}`])
             setCountrySearchBoxStatePost(false)
             }} style={countryStyleIndexPost==e.id ? {background:"purple", color: "#fff"} : null} className="country_search_card employee_add_country_search_card" key={e.id}>{e?.[`SubjectOfRF_${language.toUpperCase()}`]}</div>
              )}
            </div>
            </div>}
            </Form.Group>
            <div className="add__btn-wrapper">
          <Button className="add__btn mt-2 btn_height_default" type="submit" disabled={isLoading}>
            {isLoading ? "Saving..." : t.add}
          </Button>
          <Button
            className="add__btn mt-2 ml-2 btn_height_default"
            onClick={handleBack}
            disabled={isLoading}
          >
            {t.back}
          </Button>
        </div>
          </Form>
    </div>
  );
};

export default AddEmployee;