import React, { useState, useEffect } from "react";
import { Spinner, Form, Container } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing icons for eye
import swal from "sweetalert";
import "./Invitation/Invited.css"; // Assuming you have a CSS file for custom styles
import { fetchCountries } from "../components/apiData/apiData";
import loginbg from "../../images/bg-login.jpg";
import { useLanguage } from "../../context/LanguageContext";
import translation from '../../translation/translation'
import LanguageSelector from "../components/language-selector/LanguageSelector";
import phoneformats from '../../data-jsons/phonenumbers.json'
const InvitedUser = ({ u, token }) => {
  const [loading, setLoading] = useState(true); // Loading state
  const [valid, setValid] = useState(false); // Validation state
  const [responseData, setResponseData] = useState(null);
  const [countries, setCountries] = useState([]);
  const urlLink = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    email: "",
    company_id: "",
    password: "",
    confirmPassword: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    user_type: "",
    country: "",
    invitation_id: "",
  });
  const { language } = useLanguage()
  const t = translation[language]
  const m = translation["registration"][language]
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const validateInvitation = async () => {
      try {
        const response = await fetch(
          `${urlLink}/validate-invitation/${u}/${token}/`,
          {
            method: "GET",
          }
        );

        if (response.ok) {
          const result = await response.json();
          setResponseData(result);
          setFormData({
            email: result.email,
            company_id: result.company_id,
            user_type: result.user_type,
            invitation_id: result.invitation_id,
            password: "",
            confirmPassword: "",
            first_name: "",
            last_name: "",
            phone_number: "",
            country: "",
          });
          setValid(true);
        } else {
          const error = await response.json();
          swal(
            "Error",
            error.message || "Failed to validate the invitation",
            "error"
          ).then(() => {
            window.location.href = "/login";
          });
        }
      } catch (error) {
        swal(
          "Error",
          error.message || "Unexpected error occurred",
          "error"
        ).then(() => {
          window.location.href = "/login";
        });
      } finally {
        setLoading(false);
      }
    };

    validateInvitation();
  }, [u, token, urlLink]);

  useEffect(() => {
    const getCountries = async () => {
      const data = await fetchCountries();
      setCountries(data);
    };

    getCountries();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [searchCountryInputPut, setSearchCountryInputPut] = useState("")
  const [countriesData, setCountriesData] = useState(countries || [])
  const [countrySearchBoxState, setCountrySearchBoxState] = useState(false)
  const [countryStyleIndex, setCountryStyleIndex] = useState(null)
  const [countrySelect, setCountrySelect] = useState({})
  useEffect(() => {
    setCountriesData(countries.filter(e=>e?.["Country"+language.toUpperCase()]?.slice(0, searchCountryInputPut.length).toLowerCase() == searchCountryInputPut.trim().toLowerCase()))
    setCountrySelect(phoneformats.find(e=>e?.["country"]?.toLowerCase() == searchCountryInputPut.trim().toLowerCase())|| {})
    setCountryStyleIndex()
  }, [searchCountryInputPut])
  useEffect(() => {
    setInputPhonenumber('')
  }, [countrySelect])
  const [inputPhonenumber, setInputPhonenumber] = useState('')
  const [languageBoxState, setLanguageBoxState] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      swal("Error", "Passwords do not match", "error");
      return;
    }

    const payload = {
      email: formData.email,
      company_id: formData.company_id,
      password: formData.password,
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone_number: inputPhonenumber,
      user_type: formData.user_type,
      country: countries.find(a => a?.['Country'+language.toUpperCase()].toLowerCase() == searchCountryInputPut.trim().toLowerCase())?.id || searchCountryInputPut,
      invitation_id: formData.invitation_id,
    };
    try {
      const response = await fetch(`${urlLink}/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        swal("Success", "Registration successful!", "success").then(() => {
          window.location.href = "/login";
        });
      } else {
        const error = await response.json();
        swal("Error", error.message || "Registration failed", "error");
      }
    } catch (error) {
      swal("Error", error.message || "Unexpected error occurred", "error");
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!valid) {
    return null;
  }

  return (
    <div onClick={e => {
      if (e.target.dataset.selectLanguage !== "select-language") {
        setLanguageBoxState(false);
      }
    }} style={{ backgroundImage: "url(" + loginbg + ")" }}>
      <LanguageSelector setLanguageBoxState={setLanguageBoxState} languageBoxState={languageBoxState}/>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="form-container"
          style={{
            maxWidth: "500px",
            width: "100%",
            padding: "2rem",
            background: "#f8f9fa",
            boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
            borderRadius: "15px" 
          }}
        >
          <h1 style={language == "ru" ? {fontSize: "27px"} : null} className="text-center">
            {m.welcometoplain2do.split('Plain2do').join(' ')} {" "} <span className="invit__header">Plain2do</span>
          </h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="position-relative">
              <Form.Label className="input__label">{m.password}</Form.Label>
              <div className="password-field">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <span
                  onClick={toggleShowPassword}
                  className="password-toggle-icon"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </Form.Group>

            <Form.Group className="position-relative">
              <Form.Label className="input__label">{t.confirmpassword}</Form.Label>
              <div className="password-field">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                />
                <span
                  onClick={toggleShowConfirmPassword}
                  className="password-toggle-icon"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label className="input__label">{t.first_name}</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="input__label">{t.surname}</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formCountry">
              <Form.Label className="input__label">{t.country}</Form.Label>
              <Form.Control
                type="text"
                name="country"
                value={searchCountryInputPut}
                onChange={handleInputChange}
                required
                onBlur={() => {
                  setTimeout(() => {
                    setCountrySearchBoxState(false)
                  }, 300)
                }}
                onInput={e => {
                  setSearchCountryInputPut(e.target.value)
                }}
                onFocus={() => {
                  setCountrySearchBoxState(true)
                }}
              />
            {countrySearchBoxState && <div className="countries_search__wrapper">
              <div className="countries_container">
              {countriesData.map((e) => 
             <div onMouseOver={() => {
               setCountryStyleIndex(e.id)
             }} onClick={() => {
              setSearchCountryInputPut(e?.["Country"+language.toUpperCase()])
              setCountrySearchBoxState(false)
              }} style={countryStyleIndex==e.id ? {background:"purple", color: "#fff"} : null} className="country_search_card" key={e.value}>{e["Country"+language.toUpperCase()]}</div>
              )}
            </div>
            </div>}
            </Form.Group>
            <Form.Group>
              <Form.Label className="input__label">{t.phonenumber}</Form.Label>
              <Form.Control
                type="text"
                name="phone_number"
                value={inputPhonenumber}
               onInput={(e) => {
                        let inputValue = e.target.value
                          .split("")
                          .filter((a) => !isNaN(a) || a == "+")
                          .join("")
                          .trim("");
                        if (
                          inputValue &&
                          !inputValue.includes(countrySelect.phoneCode)
                        ) {
                          setInputPhonenumber(countrySelect.phoneCode);
                        } else {
                          if (
                            inputValue &&
                            inputValue.length > 0 &&
                            inputValue.split("").filter((a) => a === "+")
                              .length > 1
                          ) {
                            setInputPhonenumber(
                              inputValue
                                .split("")
                                .filter(
                                  (a, i) =>
                                    i !== inputValue.split("").lastIndexOf("+")
                                )
                                .join("")
                            );
                          } else
                            setInputPhonenumber(
                                inputValue.slice(0, countrySelect.phonenumber.split(" ").join("").length+1)
                            );
                        }
                      }}
                placeholder={countrySelect?.country ? phoneformats.find(e => e.country.toLowerCase() == countrySelect?.country?.toLowerCase())?.phonenumber : ''}
                required
                disabled={countrySelect?.country ? false : true}
              />
            </Form.Group>
            <button type="submit" style={{background: "purple", color: "#fff"}} className="w-100 mt-3 btn registration-demo-btn-submit">
              {t.register}
            </button>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default InvitedUser;
