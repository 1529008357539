import React, { useState, useEffect } from "react";
import "../styles.css";
import { Form, Button } from "react-bootstrap";
import swal from "sweetalert";
import accountImg from "../../../../images/account.jpg";
import RvpSearch from "../../../components/countries-search/rvp/rvp-edit/Rvp.jsx";
import RvpSearchPost from "../../../components/countries-search/rvp/rvp-add/Rvp.jsx";
const PermitFAQ = ({
  data,
  openSection,
  handleToggle,
  t,
  m,
  subjectRF,
  token,
  id,
  dataFilter,
  handleUpdate,
  employee,
  language,
}) => {
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [windowContent, setWindowContent] = useState("");
  const urlLink = process.env.REACT_APP_API_URL;

  const handleAddClick = () => {
    const addContent = (
      <div className="edit-form">
        <h3>{t.addnewvnjRvprecord}</h3>
        <Form onSubmit={handleAddSubmit}>
          <Form.Group controlId="formIssueDate">
            <Form.Label className="input__label">{t.issuedate}:</Form.Label>
            <Form.Control
              type="date"
              name="IssueDate"
              className="add__input input_height_default"
              required
            />
          </Form.Group>
          <Form.Group controlId="formValidityDate">
            <Form.Label className="input__label">{t.validitydate}:</Form.Label>
            <Form.Control
              type="date"
              name="ValidityDate"
              className="add__input input_height_default"
              required
            />
          </Form.Group>
          <Form.Group controlId="formDecisionDate">
            <Form.Label className="input__label">{t.decisiondate}:</Form.Label>
            <Form.Control
              type="date"
              name="DecisionDate"
              className="add__input input_height_default"
              required
            />
          </Form.Group>
          {/* <Form.Group controlId="formDecisionNumber">
            <Form.Label className="input__label">{t.decisionnumber}:</Form.Label>
            <Form.Control
              type="text"
              name="DecisionNumber"
              className="add__input input_height_default"
              onChange={handleDecisionNumberChange}
              required
            />
            <Form.Text className="text-muted">
              {t.format}: '1234/2024/50' or 'No'
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formDocumentSerie">
            <Form.Label className="input__label">{t.documentSerie}:</Form.Label>
            <Form.Control
              type="text"
              name="DocumentSerie"
              className="add__input input_height_default"
              onChange={handleDocumentSerieChange}
              required
            />
            <Form.Text className="text-muted">{t.entera2digitnumber}</Form.Text>
          </Form.Group> */}
          <Form.Group controlId="formDocumentNo">
            <Form.Label className="input__label">{t.documentNumber}:</Form.Label>
            <Form.Control
              type="text"
              name="DocumentNo"
              className="add__input input_height_default"
              onChange={handleDocumentNoChange}
              required
            />
            <Form.Text className="text-muted">{t.format}: '12345/12'</Form.Text>
          </Form.Group>

          <Form.Group controlId="formValidTerritories">
            <Form.Label className="input__label">{t.validterritories}:</Form.Label>
            <RvpSearch name="ValidTerritories" t={t} language={language} data={data} countries={subjectRF}/>
          </Form.Group>

          <Form.Group controlId="formIssuedBy">
            <Form.Label className="input__label">{t.issuedBy}:</Form.Label>
            <Form.Control
              type="text"
              name="IssuedBy"
              className="add__input input_height_default"
              required
            />
          </Form.Group>

          <Form.Group controlId="formFileUpload">
            <Form.Label className="input__label">{t.upload} (PDF/Image):</Form.Label>
            <Form.Control
              type="file"
              name="File"
              accept=".pdf, image/*"
              className="edit__input input_file_height_default"
            />
          </Form.Group>
          <div className="add__btn-wrapper mt-2">
            <Button className="add__btn btn_height_default" type="submit">
              {t.add}
            </Button>
          </div>
        </Form>
      </div>
    );

    setWindowContent(addContent);
    setIsWindowOpen(true);
  };

  // const handleDecisionNumberChange = (e) => {
  //   const value = e.target.value;
  //   // Apply mask or format
  //   const formattedValue = value.replace(/[^0-9\/]/g, "").slice(0, 12);
  //   e.target.value = formattedValue;
  // };

  // const handleDocumentSerieChange = (e) => {
  //   const value = e.target.value;
  //   // Apply mask or format
  //   const formattedValue = value.replace(/[^0-9]/g, "").slice(0, 2);
  //   e.target.value = formattedValue;
  // };

  const handleDocumentNoChange = (e) => {
    const value = e.target.value;
    // Apply mask or format
    const formattedValue = value.replace(/[^0-9\/]/g, "").slice(0, 9);
    e.target.value = formattedValue;
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    const formElements = e.target.elements;

    // const decisionNumber = formElements.DecisionNumber.value;
    // const documentSerie = formElements.DocumentSerie.value;
    const documentNo = formElements.DocumentNo.value;

    // Additional validation
    // if (!/^(\d{4}\/\d{4}\/\d{2}|No)$/.test(decisionNumber)) {
    //   swal(
    //     "Error",
    //     "Decision Number must be in format '1234/2024/50' or 'No'.",
    //     "error"
    //   );
    //   return;
    // }

    // if (!/^(No|\d{2})$/.test(documentSerie)) {
    //   swal(
    //     "Error",
    //     "Document Series must be either 'No' or a 2-digit number.",
    //     "error"
    //   );
    //   return;
    // }

    if (!/^(No|\d{7}|\d{5}\/\d{2})$/.test(documentNo)) {
      swal(
        t.error.charAt(0).toUpperCase() + t.error.slice(1),
        t.documentnomustbeeithera7digitnumberorintheformat,
        "error"
      );
      return;
    }

    // Create FormData object for file uploads and other data
    const formData = new FormData();
    formData.append(
      "IssueDate",
      formatDateForInput(formElements.IssueDate.value)
    );
    formData.append(
      "ValidityDate",
      formatDateForInput(formElements.ValidityDate.value)
    );
    formData.append(
      "DecisionDate",
      formatDateForInput(formElements.DecisionDate.value)
    );
    // formData.append("DecisionNumber", decisionNumber);
    // formData.append("DocumentSerie", documentSerie);
    formData.append("DocumentNo", documentNo);
    formData.append("ValidTerritories", subjectRF.find(a => a["SubjectOfRF_"+language.toUpperCase()].toLowerCase() == formElements.ValidTerritories.value.trim().toLowerCase())?.id || formElements.ValidTerritories.value);
    formData.append("IssuedBy", formElements.IssuedBy.value);
    formData.append("Employee", parseInt(id, 10));
    formData.append("Doc_Type_En", parseInt(1, 10)); // Update this value as needed

    const fileInput = formElements.File;
    if (fileInput.files[0]) {
      formData.append("file", fileInput.files[0]);
    }

    try {
      const response = await fetch(`${urlLink}/employee/Rvp/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Authorization header
        },
        body: formData, // Send FormData object
      });

      if (response.ok) {
        const result = await response.json();
        swal(t.success.charAt(0).toUpperCase() + t.success.slice(1), t.VnjRvprecordaddedsuccessfully, "success");
        handleCloseWindow();
        handleUpdate(); // Refresh the list
      } else {
        // Handle error response
        let errorData;
        try {
          const errorText = await response.text();
          errorData = JSON.parse(errorText);
        } catch (parseError) {
          errorData = { message: "Unable to parse error response" };
        }

        console.error("Error response from server:", errorData);
        throw new Error(errorData.message || t.failedtoaddVnjRvprecord);
      }
    } catch (error) {
      swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), error.message, "error");
    }
  };

  const handleEditClick = (record) => {
    const editContent = (
      <div className="edit-form">
        <h3>{t.editVnjRvprecord}</h3>
        <Form onSubmit={handleEditSubmit}>
          <Form.Group controlId="formIssueDate">
            <Form.Label className="input__label">{t.issuedate}:</Form.Label>
            <Form.Control
              type="date"
              name="IssueDate"
              defaultValue={formatDateToShow(data?.Rvp[0]?.IssueDate) || ""}
              className="edit__input input_height_default"
              required
            />
          </Form.Group>

          <Form.Group controlId="formValidityDate">
            <Form.Label className="input__label">{t.validitydate}:</Form.Label>
            <Form.Control
              type="date"
              name="ValidityDate"
              defaultValue={formatDateToShow(data?.Rvp[0]?.ValidityDate) || ""}
              className="edit__input input_height_default"
              required
            />
          </Form.Group>

          <Form.Group controlId="formDecisionDate">
            <Form.Label className="input__label">{t.decisiondate}:</Form.Label>
            <Form.Control
              type="date"
              name="DecisionDate"
              defaultValue={formatDateToShow(data?.Rvp[0]?.DecisionDate) || ""}
              className="edit__input input_height_default"
              required
            />
          </Form.Group>

          {/* <Form.Group controlId="formDecisionNumber">
            <Form.Label className="input__label">{t.decisionnumber}:</Form.Label>
            <Form.Control
              type="text"
              name="DecisionNumber"
              defaultValue={data?.Rvp[0]?.DecisionNumber}
              className="edit__input input_height_default"
              onChange={handleDecisionNumberChange}
              required
            />
            <Form.Text className="text-muted">
              {t.format}: '1234/2024/50' or 'No'
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formDocumentSerie">
            <Form.Label className="input__label">{t.documentSerie}:</Form.Label>
            <Form.Control
              type="text"
              name="DocumentSerie"
              defaultValue={data?.Rvp[0]?.DocumentSerie}
              className="edit__input input_height_default"
              onChange={handleDocumentSerieChange}
              required
            />
            <Form.Text className="text-muted">{t.entera2digitnumber}</Form.Text>
          </Form.Group> */}

          <Form.Group controlId="formDocumentNo">
            <Form.Label className="input__label">{t.documentNumber}:</Form.Label>
            <Form.Control
              type="text"
              name="DocumentNo"
              defaultValue={data?.Rvp[0]?.DocumentNo}
              className="edit__input input_height_default"
              onChange={handleDocumentNoChange}
              required
            />
            <Form.Text className="text-muted">{t.format}: '12345/12'</Form.Text>
          </Form.Group>

          <Form.Group controlId="formValidTerritories">
            <Form.Label className="input__label">{t.validterritories}:</Form.Label>
            <RvpSearchPost name="ValidTerritories" t={t} language={language} data={data} countries={subjectRF}/>
          </Form.Group>

          <Form.Group controlId="formIssuedBy">
            <Form.Label className="input__label">{t.issuedBy}:</Form.Label>
            <Form.Control
              type="text"
              name="IssuedBy"
              defaultValue={data?.Rvp[0]?.IssuedBy}
              className="edit__input input_height_default"
              required
            />
          </Form.Group>

          <Form.Group controlId="formFileUpload">
            <Form.Label className="input__label">{t.upload} (PDF/Image):</Form.Label>
            <Form.Control
              type="file"
              name="File"
              accept=".pdf, image/*"
              className="edit__input input_file_height_default"
            />
          </Form.Group>

          <div className="edit__btn-wrapper mt-2">
            <Button className="edit__btn btn_height_default" type="submit">
              {t.save}
            </Button>
          </div>
        </Form>
      </div>
    );

    setWindowContent(editContent);
    setIsWindowOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formElements = e.target.elements;

    // const decisionNumber = formElements.DecisionNumber.value;
    // const documentSerie = formElements.DocumentSerie.value;
    const documentNo = formElements.DocumentNo.value;

    // Additional validation
    // if (!/^(\d{4}\/\d{4}\/\d{2}|No)$/.test(decisionNumber)) {
    //   swal(
    //     "Error",
    //     "Decision Number must be in format '1234/2024/50' or 'No'.",
    //     "error"
    //   );
    //   return;
    // }

    // if (!/^(No|\d{2})$/.test(documentSerie)) {
    //   swal(
    //     "Error",
    //     "Document Series must be either 'No' or a 2-digit number.",
    //     "error"
    //   );
    //   return;
    // }

    if (!/^(No|\d{7}|\d{5}\/\d{2})$/.test(documentNo)) {
      swal(
        t.error.charAt(0).toUpperCase() + t.error.slice(1),
        t.documentnomustbeeithera7digitnumberorintheformat,
        "error"
      );
      return;
    }

    // Create FormData object for file uploads and other data
    const formData = new FormData();
    formData.append(
      "IssueDate",
      formatDateForInput(formElements.IssueDate.value)
    );
    formData.append(
      "ValidityDate",
      formatDateForInput(formElements.ValidityDate.value)
    );
    formData.append(
      "DecisionDate",
      formatDateForInput(formElements.DecisionDate.value)
    );
    // formData.append("DecisionNumber", decisionNumber);
    // formData.append("DocumentSerie", documentSerie);
    formData.append("DocumentNo", documentNo);
    formData.append("ValidTerritories", subjectRF.find(a => a["SubjectOfRF_"+language.toUpperCase()].toLowerCase() == formElements.ValidTerritories.value.trim().toLowerCase())?.id || formElements.ValidTerritories.value);
    formData.append("IssuedBy", formElements.IssuedBy.value);
    formData.append("Employee", parseInt(id, 10));
    formData.append("Doc_Type_En", parseInt(1, 10)); // Update this value as needed

    // Handle file input if present
    const fileInput = formElements.File;
    if (fileInput.files.length > 0) {
      formData.append("file", fileInput.files[0]);
    }

    try {
      const response = await fetch(
        `${urlLink}/employee/Rvp/${data.Rvp[0].id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header
          },
          body: formData, // Send FormData object
        }
      );

      if (response.ok) {
        const result = await response.json();
        swal(t.success.charAt(0).toUpperCase() + t.success.slice(1), t.Rvprecordupdatedsuccessfully, "success");
        handleCloseWindow();
        handleUpdate(); // Refresh the list
      } else {
        // Handle error response
        let errorData;
        try {
          const errorText = await response.text();
          errorData = JSON.parse(errorText);
        } catch (parseError) {
          errorData = { message: "Unable to parse error response" };
        }

        console.error("Error response from server:", errorData);
        throw new Error(errorData.message || t.failedtoupdateVnjRvprecord);
      }
    } catch (error) {
      swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), error.message, "error");
    }
  };

  const formatDateForInput = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  const formatDateToShow = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split(".");
    return `${year}-${month}-${day}`;
  };

  const handleCloseWindow = () => {
    setIsWindowOpen(false);
  };

  const handlePdfClick = () => {
    const file = data.Rvp[0]?.file;

    const cleanFileUrl = file?.split("?")[0];

    let content;
    if (cleanFileUrl?.endsWith(".pdf")) {
      content = (
        <iframe
          src={file}
          width="100%"
          height="100%"
          title="PDF Viewer"
          frameBorder="0"
          allowFullScreen
        />
      );
    } else {
      content = (
        <img
          className="sliding-image"
          src={file || accountImg}
          alt="PDF or Image"
        />
      );
    }

    setWindowContent(content);
    setIsWindowOpen(true);
  };
  const [datacheck, setDatacheck] = useState(true)
  useEffect(() => {
    if(!data?.Rvp[0]){
      setDatacheck(false)
    }
    for(let i in data?.Rvp[0]){
      if(typeof data?.Rvp[0]?.[i] === "string" && data?.Rvp[0]?.[i] == ""){
        setDatacheck(false)
      }
      else if(data?.Rvp[0]?.[i] === null&& i != "file") setDatacheck(false)
      else if(data?.Rvp[0]?.[i] instanceof  Object && Object.keys(data?.Rvp[0]?.[i]).length == 0){
        setDatacheck(false)
      }
      else setDatacheck(true)
    }
  }, [data?.Rvp[0]])
  return (
    <div style={!datacheck ? {background: "rgb(223,223,223)"} : null} className="faq-container8">
      <div
        onClick={() => handleToggle("residencePermit")}
        className="faq__t-wrapper"
      >
        <h5 className="toggle-title">{t.residencePermitInformation}</h5>
        <p className="faq__header-seria">
          {data?.Rvp && data?.Rvp.length > 0
            ? data?.Rvp[0].IssueDate
            : t.noResidencePermitInformation}
        </p>
      </div>
      <div
        className={`collapsible-content ${
          openSection === "residencePermit" ? "open" : ""
        }`}
      >
        {data?.Rvp && data?.Rvp.length > 0 ? (
          <div className="faq-details">
            <div className="faq__top-wrapper">
              <p className="faq__section-title">{t.residencePermit}</p>

              <span className="faq__line"></span>
              <div className="faq__wrapper">
                <p className="faq__topsubheader">{t.documentno}</p>
                <p className="faq__country-code">{data?.Rvp[0]?.DocumentNo}</p>
              </div>
            </div>
            <div className="faq__main-wrapper">
              <div className="faq__info">
                <div className="faq-field">
                  <span className="field-label">{m.fullname}:</span>
                  <p className="field__data">
                    {employee.firstname} {employee.surname}
                  </p>
                </div>
                <div className="faq-field">
                  <span className="field-label">{t.issuedBy}:</span>
                  <p className="field__data">{data?.Rvp[0]?.IssuedBy}</p>
                </div>
                <div className="faq__birthwrapper">
                  <div className="faq-field">
                    <span className="field-label">{t.validterritories}:</span>
                    <p className="field__data">
                      {
                        data?.Rvp[0].ValidTerritories_data[
                          "SubjectOfRF_" + language.toUpperCase()
                        ]
                      }
                    </p>
                  </div>
                  <div className="faq-field">
                    <span className="field-label">{t.validdate}:</span>
                    <p className="field__data">{data?.Rvp[0]?.ValidityDate}</p>
                  </div>
                </div>
                <button
                  className="btn btn-secondary mt-2 me-2 faq__edit-btn"
                  onClick={handleEditClick}
                >
                  <i className="fa fa-pencil"></i> {t.edit}
                </button>

                <button
                  className=" btn btn-secondary mt-2 faq__pdf-btn"
                  onClick={handlePdfClick}
                >
                  PDF
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-data">
            {dataFilter === "latest" ? (
              <div className="no-data-wrapper">
                <p className="no-data">{t.noData}</p>
                <div className="faq__add-btn-wrapper">
                  <Button className="faq__edit-btn" onClick={handleAddClick}>
                    {t.eddresidencepermitinformation}
                  </Button>
                </div>
              </div>
            ) : (
              <p>{t.noData}</p>
            )}
          </div>
        )}
      </div>

      {isWindowOpen && (
        <div className="overlay show" onClick={handleCloseWindow}>
          <div
            className="sliding-window show"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-btn" onClick={handleCloseWindow}>
              &times;
            </span>
            {windowContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default PermitFAQ;