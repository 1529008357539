const initialData = {
	list : []
}
const todoReducers = (state = initialData, action) =>{
	switch(action.type){
		case "ADD_TODO" : 
		const {id, data} = action.payload;
		return{
			...state,
			list: [
				...state.list,
				{
					id: id,
					data: data,
				}
			]
		}
		case "DELETE_TODO" : 
		const newList = state.list.filter((elem) => elem.id !== action.id)
		
		return{
			...state,
			list: newList 
		}
		
		case "REMOVE_TODO" : return{
			...state,
			list: []
		}
		default: return state;
           
	}
}

const initialState = {
	companies : []
}
const companyReducer = (state = initialState, action) =>{
	switch(action.type){
		case "COMPANIES" : 
		const {data} = action;
		return{
			companies: data
		}
		default: return state;
           
	}
}

const initialPassportState = {
	passport : {}
}

const autofillReducer = (state = initialPassportState, action) =>{
	switch(action.type){
		case "AUTOFILL" : 
		const {data} = action;
		return{
			passport: data,
			photo: action.photo
		}
		default: return state;
           
	}
}

export {todoReducers, companyReducer, autofillReducer};