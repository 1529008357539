import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { getDecodedRefreshTokenFromLocalStorage } from "../../../jwt/jwtDecoder";
import { useLanguage } from "../../../context/LanguageContext";
import translations from "../../../translation/translation";
import { fetchOperationType } from "../../components/apiData/apiFinance";
import {
    fetchCurrency,
    fetchEmployee,
  } from "../../components/apiData/apiEmployee";
  import { fetchProjects } from "../../components/apiData/apiData";
  import { useSelector } from 'react-redux'
const AddTreasury = () => {
  const {companies} = useSelector(data => data.companyReducer)
  const { tab } = useParams()
    const parser = JSON.parse(localStorage.getItem("userDetails"));
    const token = parser.access;
    const { language } = useLanguage();
    const t = translations[language];
    const m = translations.payroll[language];
    const [errors, setErrors] = useState({});
    const [contents, setContents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedTreasure, setSelectedTreasure] = useState(null);
    const [operationType, setOperationType] = useState([]);
    const [countryParty, setCountryParty] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [projects, setProjects] = useState([]);
    const history = useNavigate();
    const urlLink = process.env.REACT_APP_API_URL;
    const decodedToken = getDecodedRefreshTokenFromLocalStorage("userDetails");
    const id = decodedToken.payload.user_id;
    const CompanyId = decodedToken.payload.company_id;
    const [filteredOperationType, setFilteredOperationType] = useState([]);
    const [transactionType, setTransactionType] = useState("");
    const [companiesInputState, setCompaniesInputState] = useState(false)
    const initialTreasureState = {
      our_company: 0,
      operation_type: 0,
      counter_party: 0,
      date: "",
      document_number: 0,
      transaction_method: "",
      bank_account: "",
      bank_account_cp: 0,
      expense_type: "",
      currency: "",
      amount: 0,
      vat: 0,
      vat_amount: 0,
      payment_details: "",
      comment: "",
      kbbo_code: "",
      cr: 0,
      dr: 0,
      responsible_user: 0,
      cost_center: 0,
      file: null, // Add this for file upload
    };
  
    const [newTreasure, setNewTreasure] = useState(initialTreasureState);
  
    // Fetching Options
  
    useEffect(() => {
      const getOperationType = async () => {
        const data = await fetchOperationType(token);
        setOperationType(data);
      };
  
      getOperationType();
    }, [token]);
  
    useEffect(() => {
      const getCountryParty = async () => {
        const data = await fetchEmployee(token);
        setCountryParty(data);
      };
  
      getCountryParty();
    }, [token]);
  
    useEffect(() => {
      const getCurrency = async () => {
        const data = await fetchCurrency(token);
        setCurrency(data);
      };
  
      getCurrency();
    }, [token]);
  
    useEffect(() => {
      const getProjects = async () => {
        const data = await fetchProjects(token);
        setProjects(data);
      };
  
      getProjects();
    }, [token]);
  
    // Main fetch API
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setNewTreasure((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleAddTreasure = (event) => {
      event.preventDefault();
  
      const formData = new FormData();
      for (const key in newTreasure) {
        formData.append(key, newTreasure[key]);
      }
      formData.append("responsible_user", id);
      formData.append("our_company", companies.find(a => a?.["OurCompany"+language.toUpperCase()].toLowerCase() == searchCountryInput.trim().toLowerCase())?.id || swal("Error", "The user does not exist", "error"));
      formData.append("transaction_method", tab);
      formData.set("date", formatDate(newTreasure.date));
      formData.append("counter_party", countryParty.find(a => `${a.surname} ${a.firstname}`.toLowerCase() == searchCountryInputPost.trim().toLowerCase()  || `${a.firstname} ${a.surname}`.toLowerCase() == searchCountryInputPost.trim().toLowerCase())?.id || swal("Error", "The user does not exist", "error"));
      const url = `${urlLink}/finance/`;
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };
  
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            return response.json().then((data) => {
              throw data;
            });
          }
          return response.json();
        })
        .then((data) => {
          localStorage.setItem("tab", tab)
          const savedData = data["Data is successfully saved"];
          setContents((prev) => [...prev, savedData]);
          swal(t.success.charAt(0).toUpperCase() + t.success.slice(1),t.treasureaddedsuccessfully, "success");
          setErrors({});
          history(-1)
        })
        .catch((error) => {
          if (typeof error === "object") {
            const validationErrors = {};
            for (const key in error) {
              if (error.hasOwnProperty(key)) {
                validationErrors[key] = error[key][0];
              }
            }
            setErrors(validationErrors);
          } else {
            swal(
              t.error.charAt(0).toUpperCase() + t.error.slice(1),
              t.failedtoaddtreasure + (error.message || t.anerrorocurred),
              "error"
            );
          }
        });
    };
  
    //Render
    const formatDate = (date) => {
      const [year, month, day] = date?.split("-");
      return `${day}.${month}.${year}`;
    };
    const [searchCountryInputPost, setSearchCountryInputPost] = useState('')
    const [countriesDataPost, setCountriesDataPost] = useState(countryParty || [])
    const [countrySearchBoxStatePost, setCountrySearchBoxStatePost] = useState(false)
    const [countryStyleIndexPost, setCountryStyleIndexPost] = useState(null)
    useEffect(() => {
    setCountriesDataPost(countryParty.filter(e=> e.firstname.slice(0, searchCountryInputPost.length).toLowerCase() == searchCountryInputPost.trim().toLowerCase() || e.surname.slice(0, searchCountryInputPost.length).toLowerCase() == searchCountryInputPost.trim().toLowerCase() || `${e.surname} ${e.firstname}`.slice(0, searchCountryInputPost.length).toLowerCase() == searchCountryInputPost.trim().toLowerCase() || `${e.firstname} ${e.surname}`.slice(0, searchCountryInputPost.length).toLowerCase() == searchCountryInputPost.trim().toLowerCase()))
    setCountryStyleIndexPost('')  
  }, [searchCountryInputPost])

    const [searchCountryInput, setSearchCountryInput] = useState('')
    const [countriesData, setCountriesData] = useState(companies || [])
    const [countrySearchBoxState, setCountrySearchBoxState] = useState(false)
    const [countryStyleIndex, setCountryStyleIndex] = useState(null)
    useEffect(() => {
    setCountriesData(companies.filter(e=> e?.["OurCompany"+language.toUpperCase()].slice(0, searchCountryInput.length).toLowerCase() === searchCountryInput.trim().toLowerCase()))
    setCountryStyleIndex('')  
  }, [searchCountryInput])
    useEffect(() => {
      if (transactionType) {
        setFilteredOperationType(
          operationType.filter(
            (operation) => operation.transaction_type === transactionType
          )
        );
      } else {
        setFilteredOperationType([]);
      }
    }, [transactionType, operationType]);
  
    useEffect(() => {
      if (selectedTreasure?.operation_type_data?.transaction_type) {
        setTransactionType(selectedTreasure.operation_type_data.transaction_type);
      }
    }, [selectedTreasure]);

  const handleBack = () => {
    history(-1);
  };
  const [isLoading, setIsLoading] = useState(false);
  
  return (
    <div onClick={e => {
        if(e.target.dataset.employeeSearch != "search"){
          setCountrySearchBoxStatePost(false)
        }
        if(e.target.dataset.employeeSearch != "search-company"){
          setCountrySearchBoxState(false)
        }
      }}>
      {isLoading && (
        <div className="fullscreen-overlay">
          <div className="spinner-container">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading...</p>
          </div>
        </div>
      )}
       <Form onSubmit={handleAddTreasure}>
            {/* Transaction Type */}
            <div className="addemployee-input-wrapper">
            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.transaction}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                as="select"
                name="transaction_type"
                value={transactionType}
                onChange={(e) => setTransactionType(e.target.value)}
                required
              >
                <option disabled selected value="">
                  {t.select}
                </option>
                <option value="Receipt">{t.receipt}</option>
                <option value="Payment">{t.payment}</option>
              </Form.Control>
            </Form.Group>

            {/* Operation Type */}
            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.operationtype}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                as="select"
                name="operation_type"
                value={newTreasure.operation_type}
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: "operation_type",
                      value: parseInt(e.target.value, 10) || "",
                    },
                  })
                }
                isInvalid={!!errors.operation_type}
                required
              >
                <option value="">{t.selectoperationtype}</option>
                {filteredOperationType.map((operation) => (
                  <option key={operation.id} value={operation.id}>
                    {operation["name" + language.toUpperCase()]}
                  </option>
                ))}
              </Form.Control>
              {errors.operation_type && (
                <Form.Control.Feedback type="invalid">
                  {errors.operation_type}
                </Form.Control.Feedback>
              )}
            </Form.Group>
</div>
<div className="addemployee-input-wrapper">
            {/* Counter Party */}
            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.counter_party}</Form.Label>
              <Form.Control data-employee-search="search"
              type="text"
              name={"counter_party"}
              value={searchCountryInputPost}
              className="edit__input employee-fill-wrapper-input"
              onInput={e => {
                setSearchCountryInputPost(e.target.value)
                setCountrySearchBoxStatePost(true)
              }}
              onClick={() => {
                setCountrySearchBoxStatePost(true)
              }}
              placeholder={t.selectcounterparty}
            />
            {countrySearchBoxStatePost && <div data-employee-search="search" className="countries_search__wrapper">
              <div className="countries_container">
              {countriesDataPost.map((e) => 
             <div data-employee-search="search" onMouseOver={() => {
               setCountryStyleIndexPost(e.id)
             }} onClick={() => {
              setSearchCountryInputPost(`${e?.surname} ${e?.firstname}`)
              setCountrySearchBoxStatePost(false)
              }} style={countryStyleIndexPost==e.id ? {background:"purple", color: "#fff"} : null} className="country_search_card" key={e.id}>{e?.firstname} {e?.surname}</div>
              )}
            </div>
            </div>}
              {errors.counter_party && (
                <Form.Control.Feedback type="invalid">
                  {errors.counter_party}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {/* Date */}
            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{m.date}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="date"
                name="date"
                value={newTreasure.date}
                onChange={handleInputChange}
                isInvalid={!!errors.date}
                required
              />
              {errors.date && (
                <Form.Control.Feedback type="invalid">
                  {errors.date}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            </div>
            <div className="addemployee-input-wrapper">
            {/* Document Number */}
            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.documentNumber}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="number"
                name="document_number"
                value={newTreasure.document_number}
                onChange={handleInputChange}
                isInvalid={!!errors.document_number}
                required
              />
              {errors.document_number && (
                <Form.Control.Feedback type="invalid">
                  {errors.document_number}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {/* Bank Account */}
            {/* <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.bankaccount}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="text"
                name="bank_account"
                value={newTreasure.bank_account}
                onChange={handleInputChange}
                isInvalid={!!errors.bank_account}
                required
              />
              {errors.bank_account && (
                <Form.Control.Feedback type="invalid">
                  {errors.bank_account}
                </Form.Control.Feedback>
              )}
            </Form.Group> */}

            {/* Bank Account CP */}
            {/* <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.bankaccountcp}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="number"
                name="bank_account_cp"
                value={newTreasure.bank_account_cp}
                onChange={handleInputChange}
                isInvalid={!!errors.bank_account_cp}
                required
              />
              {errors.bank_account_cp && (
                <Form.Control.Feedback type="invalid">
                  {errors.bank_account_cp}
                </Form.Control.Feedback>
              )}
            </Form.Group> */}

            {/* Expense Type */}
            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.expensetype}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="text"
                name="expense_type"
                value={newTreasure.expense_type}
                onChange={handleInputChange}
                isInvalid={!!errors.expense_type}
                required
              />
              {errors.expense_type && (
                <Form.Control.Feedback type="invalid">
                  {errors.expense_type}
                </Form.Control.Feedback>
              )}
            </Form.Group>
</div>
<div className="addemployee-input-wrapper">
            {/* Currency */}
            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.currency}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                as="select"
                name="currency"
                value={newTreasure.currency}
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: "currency",
                      value: parseInt(e.target.value, 10) || "",
                    },
                  })
                }
                isInvalid={!!errors.currency}
                required
              >
                <option value="">{t.selectcurrency}</option>
                {currency.map((currency) => (
                  <option key={currency.id} value={currency.id}>
                    {currency["Currency" + language.toUpperCase()]}
                  </option>
                ))}
              </Form.Control>
              {errors.currency && (
                <Form.Control.Feedback type="invalid">
                  {errors.currency}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {/* Amount */}
            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.amount}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="number"
                name="amount"
                value={newTreasure.amount}
                onChange={handleInputChange}
                isInvalid={!!errors.amount}
                required
              />
              {errors.amount && (
                <Form.Control.Feedback type="invalid">
                  {errors.amount}
                </Form.Control.Feedback>
              )}
            </Form.Group>
</div>
<div className="addemployee-input-wrapper">
            {/* VAT */}
            {/* <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">VAT</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="number"
                name="vat"
                value={newTreasure.vat}
                onChange={handleInputChange}
                isInvalid={!!errors.vat}
                required
              />
              {errors.vat && (
                <Form.Control.Feedback type="invalid">
                  {errors.vat}
                </Form.Control.Feedback>
              )}
            </Form.Group> */}

            {/* VAT Amount */}
            {/* <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.vatamount}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="number"
                name="vat_amount"
                value={newTreasure.vat_amount}
                onChange={handleInputChange}
                isInvalid={!!errors.vat_amount}
                required
              />
              {errors.vat_amount && (
                <Form.Control.Feedback type="invalid">
                  {errors.vat_amount}
                </Form.Control.Feedback>
              )}
            </Form.Group> */}

            {/* Payment Details */}
            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.paymentdetails}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="text"
                name="payment_details"
                value={newTreasure.payment_details}
                onChange={handleInputChange}
                isInvalid={!!errors.payment_details}
                required
              />
              {errors.payment_details && (
                <Form.Control.Feedback type="invalid">
                  {errors.payment_details}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {/* Comment */}
            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.comment}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="text"
                name="comment"
                value={newTreasure.comment}
                onChange={handleInputChange}
                isInvalid={!!errors.comment}
              />
              {errors.comment && (
                <Form.Control.Feedback type="invalid">
                  {errors.comment}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            </div>
            <div className="addemployee-input-wrapper">
            {/* KBBO Code */}
            {/* <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">
                {t.kbbo} {t.code}
              </Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="text"
                name="kbbo_code"
                value={newTreasure.kbbo_code}
                onChange={handleInputChange}
                isInvalid={!!errors.kbbo_code}
              />
              {errors.kbbo_code && (
                <Form.Control.Feedback type="invalid">
                  {errors.kbbo_code}
                </Form.Control.Feedback>
              )}
            </Form.Group> */}

            {/* CR */}
            {/* <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">CR</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="number"
                name="cr"
                value={newTreasure.cr}
                onChange={handleInputChange}
                isInvalid={!!errors.cr}
              />
              {errors.cr && (
                <Form.Control.Feedback type="invalid">
                  {errors.cr}
                </Form.Control.Feedback>
              )}
            </Form.Group> */}

            {/* DR */}
            {/* <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">DR</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                type="number"
                name="dr"
                value={newTreasure.dr}
                onChange={handleInputChange}
                isInvalid={!!errors.dr}
              />
              {errors.dr && (
                <Form.Control.Feedback type="invalid">
                  {errors.dr}
                </Form.Control.Feedback>
              )}
            </Form.Group> */}

            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.costcenter}</Form.Label>
              <Form.Control
              className="employee-fill-wrapper-input"
                as="select"
                name="cost_center"
                value={newTreasure.cost_center}
                isInvalid={!!errors.cost_center}
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: "cost_center",
                      value: parseInt(e.target.value, 10) || "",
                    },
                  })
                }
                required
              >
                {" "}
                <option value="">{t.selectproject}</option>
                {projects.map((projects) => (
                  <option key={projects.id} value={projects.id}>
                    {projects?.ProjectNameEN}
                  </option>
                ))}
              </Form.Control>
              {errors.cost_center && (
                <Form.Control.Feedback type="invalid">
                  {errors.cost_center}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">File</Form.Label>
              <Form.Control
                type="file"
                className="input_file_height_default"
                name="file"
                accept=".pdf, image/*"
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: "file",
                      value: e.target.files[0], // Save the file object
                    },
                  })
                }
                isInvalid={!!errors.file}
              />
              {errors.file && (
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            </div>
            <Form.Group>
              <Form.Label className="input__label mt-0">{t.company}</Form.Label>
              <Form.Control data-employee-search="search"
              type="text"
              name={"our_company"}
              value={searchCountryInput}
              disabled={companiesInputState}
              className="edit__input employee-fill-wrapper-input"
              onInput={e => {
                setSearchCountryInput(e.target.value)
                setCountrySearchBoxState(true)
              }}
              onClick={() => {
                setCountrySearchBoxState(true)
              }}
              placeholder={t.selectcounterparty}
            />
            {countrySearchBoxState && <div data-employee-search="search-company" className="countries_search__wrapper">
              <div className="countries_container">
              {countriesData?.length && countriesData?.map((e) => 
             <div data-employee-search="search-company" onMouseOver={() => {
               setCountryStyleIndex(e.id)
             }} onClick={() => {
              setSearchCountryInput(e?.["OurCompany"+language.toUpperCase()])
              setCountrySearchBoxState(false)
              }} style={countryStyleIndex==e.id ? {background:"purple", color: "#fff"} : null} className="country_search_card" key={e.id}>{e?.["OurCompany"+language.toUpperCase()]}</div>
              )}
            </div>
            </div>}
              {errors.counter_party && (
                <Form.Control.Feedback type="invalid">
                  {errors.counter_party}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <div className="add__btn-wrapper">
          <Button className="add__btn mt-2 btn_height_default" type="submit" disabled={isLoading}>
            {isLoading ? "Saving..." : t.add}
          </Button>
          <Button
            className="add__btn mt-2 ml-2 btn_height_default"
            onClick={handleBack}
            disabled={isLoading}
          >
            {t.back}
          </Button>
        </div>
          </Form>
    </div>
  );
};

export default AddTreasury;