import React, {useEffect, useState} from 'react'
import { Form } from "react-bootstrap";
const CountrySearch = ({countries, data, name, t}) => {
  const [searchCountryInputPut, setSearchCountryInputPut] = useState(countries.find(e => e.value == data?.JotTitle_data?.id)?.label ||"")
  const [countriesData, setCountriesData] = useState(countries || [])
  const [countrySearchBoxState, setCountrySearchBoxState] = useState(false)
  const [countryStyleIndex, setCountryStyleIndex] = useState(data?.JotTitle_data?.id || null)
  useEffect(() => {
    setCountriesData(countries.filter(e=>e.label.slice(0, searchCountryInputPut.length).toLowerCase() == searchCountryInputPut.trim().toLowerCase()))
    setCountryStyleIndex()
  }, [searchCountryInputPut])
  return (
    <div>
      <Form.Control
              type="text"
              name={name}
              value={searchCountryInputPut}
              className="edit__input input_height_default"
              onBlur={() => {
                setTimeout(() => {
                  setCountrySearchBoxState(false)
                }, 300)
              }}
              onInput={e => {
                setSearchCountryInputPut(e.target.value)
              }}
              onFocus={() => {
                setCountrySearchBoxState(true)
              }}
              placeholder={t.enterjobtitle}
            />
            {countrySearchBoxState && <div className="countries_search__wrapper">
              <div className="countries_container">
              {countriesData.map((e) => 
             <div onMouseOver={() => {
               setCountryStyleIndex(e.value)
             }} onClick={() => {
              setSearchCountryInputPut(e.label)
              setCountrySearchBoxState(false)
              }} style={countryStyleIndex==e.value ? {background:"purple", color: "#fff"} : null} className="country_search_card" key={e.value}>{e.label}</div>
              )}
            </div>
            </div>}
    </div>
  )
}

export default CountrySearch
