import React, { useEffect, useState } from "react";
import "../styles.css";
import swal from "sweetalert";
import { Button, Form, Modal } from "react-bootstrap";
import accountImg from "../../../../images/account.jpg";
import RegionSearch from "../../../components/countries-search/patent/region-search-edit/RegionSearch";
import RegionSearchPost from "../../../components/countries-search/patent/region-search-add/RegionSearch";
const PatentFAQ = ({
  data,
  openSection,
  handleToggle,
  t,
  m,
  id,
  language,
  token,
  subjectRF,
  dataFilter,
  handleUpdate,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [windowContent, setWindowContent] = useState("");
  const urlLink = process.env.REACT_APP_API_URL;
  const [content, setContent] = useState([]);
  useEffect(() => {
    const url = `${urlLink}/employee/patent/${id}/?type=cheque`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${t.httperrorstatus}${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setContent(data.Response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          t.error.charAt(0).toUpperCase() + t.error.slice(1),
          t.therewasissuewithfetchoperation + error.message,
          "error"
        );
      }); // Set loading to false after fetch is complete
  }, [token, id]);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const formatDateForInput = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  const formatDateToShow = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split(".");
    return `${year}-${month}-${day}`;
  };

  const handlePdfClick = () => {
    const file = data.Patent[0]?.file;

    const cleanFileUrl = file?.split("?")[0];

    let content;
    if (cleanFileUrl?.endsWith(".pdf")) {
      content = (
        <iframe
          src={file}
          width="100%"
          height="100%"
          title="PDF Viewer"
          frameBorder="0"
          allowFullScreen
        />
      );
    } else {
      content = (
        <img
          className="sliding-image"
          src={file || accountImg}
          alt="PDF or Image"
        />
      );
    }

    setWindowContent(content);
    setIsWindowOpen(true);
  };

  const handleEditClick = () => {
    const editContent = (
      <div className="edit-form">
        <h3>{t.editpatentinformation}</h3>
        <Form onSubmit={handleEditSubmit}>
          <Form.Group controlId="formDocumentSerie">
            <Form.Label className="input__label">{t.documentSerie}:</Form.Label>
            <Form.Control
              type="number"
              name="DocumentSerie"
              defaultValue={data?.Patent[0]?.DocumentSerie || ""}
              className="edit__input input_height_default"
              min="10"
              max="99"
              step="1"
              required
              onInput={(e) => {
                if (e.target.value.length > 2) {
                  e.target.value = e.target.value.slice(0, 2);
                }
              }}
            />
          </Form.Group>

          <Form.Group controlId="formDocumentNo">
            <Form.Label className="input__label">{t.documentNumber}:</Form.Label>
            <Form.Control
              type="number"
              name="DocumentNo"
              defaultValue={data?.Patent[0]?.DocumentNo || ""}
              className="edit__input input_height_default"
              min="1000000000" // Minimum value greater than 1000000000
              required
            />
          </Form.Group>

          <Form.Group controlId="formIssueDate">
            <Form.Label className="input__label">{t.issuedate}:</Form.Label>
            <Form.Control
              type="date"
              name="IssueDate"
              defaultValue={formatDateToShow(data?.Patent[0]?.IssueDate) || ""}
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formValidityDate">
            <Form.Label className="input__label">{t.validityDate}:</Form.Label>
            <Form.Control
              type="date"
              name="ValidityDate"
              defaultValue={
                formatDateToShow(data?.Patent[0]?.ValidityDate) || ""
              }
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formJobTitle">
            <Form.Label className="input__label">{m.jobtitle}:</Form.Label>
            <Form.Control
              type="text"
              name="Job_Title"
              defaultValue={data?.Patent[0]?.Job_Title || ""}
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formSpecialMarks">
            <Form.Label className="input__label">{t.specialmarks}:</Form.Label>
            <Form.Control
              as="select"
              name="SpecialMarks"
              className="edit__input input_height_default"
              defaultValue={data?.Patent[0]?.SpecialMarks || "Работа у ЮЛ/ИП"}
            >
              <option value="ВКС">ВКС</option>
              <option value="Работа у ЮЛ/ИП">Работа у ЮЛ/ИП</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formValidTerritories">
            <Form.Label className="input__label">{t.validterritories}:</Form.Label>
              <RegionSearch name="ValidTerritories" t={t} language={language} data={data} countries={subjectRF} />
          </Form.Group>

          <Form.Group controlId="formIssuedBy">
            <Form.Label className="input__label">{t.issuedBy}:</Form.Label>
            <Form.Control
              type="text"
              name="Issued_by"
              defaultValue={data?.Patent[0]?.Issued_by || ""}
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formRegisterSerialNumber">
            <Form.Label className="input__label">{t.registerSerialNumber}:</Form.Label>
            <Form.Control
              type="text"
              name="Register_Serial_number"
              defaultValue={data?.Patent[0]?.Register_Serial_number || ""}
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formFileUpload">
            <Form.Label className="input__label">{t.upload} (PDF/Image):</Form.Label>
            <Form.Control
              type="file"
              name="File"
              accept=".pdf, image/*"
              className="edit__input input_file_height_default"
            />
          </Form.Group>

          <div className="edit__btn-wrapper mt-2">
            <Button className="edit__btn btn_height_default" type="submit">
              {t.save}
            </Button>
          </div>
        </Form>
      </div>
    );
    setWindowContent(editContent);
    setIsWindowOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const formElements = e.target.elements;
    const fileInput = formElements.File;

    const formattedIssueDate = formatDateForInput(formElements.IssueDate.value);
    const formattedValidityDate = formatDateForInput(
      formElements.ValidityDate.value
    );

    // Append form data
    if (fileInput.files[0]) {
      formData.append("file", fileInput.files[0]);
    }
    formData.append("DocumentSerie", formElements.DocumentSerie.value);
    formData.append("DocumentNo", formElements.DocumentNo.value);
    formData.append("IssueDate", formattedIssueDate);
    formData.append("ValidityDate", formattedValidityDate);
    formData.append("Job_Title", formElements.Job_Title.value);
    formData.append("SpecialMarks", formElements.SpecialMarks.value);
    formData.append("ValidTerritories", subjectRF.find(a => a["SubjectOfRF_"+language.toUpperCase()].toLowerCase() == formElements.ValidTerritories.value.trim().toLowerCase())?.id || formElements.ValidTerritories.value);
    formData.append("Issued_by", formElements.Issued_by.value);
    formData.append(
      "Register_Serial_number",
      formElements.Register_Serial_number.value
    );

    try {
      // Adjust URL according to the endpoint used for editing patent information
      const response = await fetch(
        `${urlLink}/employee/patent/${data.Patent[0].id}/`, // Make sure `data.Patent[0].id` reflects the correct ID
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        swal(t.success.charAt(0).toUpperCase() + t.success.slice(1), t.patentinformationupdatedsuccessfully, "success");
        handleCloseWindow(); // Close the modal window
        handleUpdate(); // Function to refresh the data or re-render the component
      } else {
        const errorData = await response.json();
        throw new Error(
          errorData.message || t.failedtoupdatepatentinformation
        );
      }
    } catch (error) {
      swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), error.message, "error");
    }
  };

  const handleAddClick = () => {
    const addContent = (
      <div className="edit-form">
        <h3>{t.addnewpatentinformation}</h3>
        <Form onSubmit={handleAddSubmit}>
          <Form.Group controlId="formDocumentSerie">
            <Form.Label className="input__label">{t.documentSerie}:</Form.Label>
            <Form.Control
              type="number"
              name="DocumentSerie"
              className="edit__input input_height_default"
              min="10"
              max="99"
              step="1"
              required
              onInput={(e) => {
                if (e.target.value.length > 2) {
                  e.target.value = e.target.value.slice(0, 2);
                }
              }}
            />
          </Form.Group>

          <Form.Group controlId="formDocumentNo">
            <Form.Label className="input__label">{t.documentNumber}:</Form.Label>
            <Form.Control
              type="number"
              name="DocumentNo"
              className="edit__input input_height_default"
              min="1000000001"
              max="9999999999"
              required
            />
          </Form.Group>

          <Form.Group controlId="formIssueDate">
            <Form.Label className="input__label">{t.issuedate}:</Form.Label>
            <Form.Control
              type="date"
              name="IssueDate"
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formValidityDate">
            <Form.Label className="input__label">{t.validityDate}:</Form.Label>
            <Form.Control
              type="date"
              name="ValidityDate"
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formJobTitle">
            <Form.Label className="input__label">{m.jobtitle}:</Form.Label>
            <Form.Control
              type="text"
              name="Job_Title"
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formSpecialMarks">
            <Form.Label className="input__label">{t.specialmarks}:</Form.Label>
            <Form.Control
              as="select"
              name="SpecialMarks"
              className="edit__input input_height_default"
              defaultValue="Работа у ЮЛ/ИП"
            >
              <option value="ВКС">ВКС</option>
              <option value="Работа у ЮЛ/ИП">Работа у ЮЛ/ИП</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formValidTerritories">
            <Form.Label className="input__label">{t.validterritories}:</Form.Label>
            <RegionSearchPost name="ValidTerritories" t={t} language={language} data={data} countries={subjectRF} />
          </Form.Group>

          <Form.Group controlId="formIssuedBy">
            <Form.Label className="input__label">{t.issuedBy}:</Form.Label>
            <Form.Control
              type="text"
              name="Issued_by"
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formRegisterSerialNumber">
            <Form.Label className="input__label">{t.registerSerialNumber}:</Form.Label>
            <Form.Control
              type="text"
              name="Register_Serial_number"
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formFileUpload">
            <Form.Label className="input__label">{t.upload} (PDF/Image):</Form.Label>
            <Form.Control
              type="file"
              name="File"
              accept=".pdf, image/*"
              className="edit__input input_file_height_default"
            />
          </Form.Group>

          <div className="edit__btn-wrapper mt-2">
            <Button className="edit__btn btn_height_default" type="submit">
              {t.save}
            </Button>
          </div>
        </Form>
      </div>
    );
    setWindowContent(addContent);
    setIsWindowOpen(true);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const formElements = e.target.elements;
    const fileInput = formElements.File;

    const formattedIssueDate = formatDateForInput(formElements.IssueDate.value);
    const formattedValidityDate = formatDateForInput(
      formElements.ValidityDate.value
    );
    const employeeId = parseInt(id, 10);
    const docTypeEn = parseInt(15, 10);
    const docSerie = parseInt(formElements.DocumentSerie.value, 10);
    const documentNo = parseInt(formElements.DocumentNo.value, 10);

    formData.append("DocumentSerie", docSerie);
    if (fileInput.files[0]) {
      formData.append("file", fileInput.files[0]);
    }
    formData.append("DocumentNo", documentNo);
    formData.append("IssueDate", formattedIssueDate);
    formData.append("ValidityDate", formattedValidityDate);
    formData.append("Doc_Type_En", docTypeEn);
    formData.append("Employee", employeeId);
    formData.append("Job_Title", formElements.Job_Title.value);
    formData.append("SpecialMarks", formElements.SpecialMarks.value);
    formData.append("ValidTerritories", subjectRF.find(a => a["SubjectOfRF_"+language.toUpperCase()].toLowerCase() == formElements.ValidTerritories.value.trim().toLowerCase())?.id || formElements.ValidTerritories.value);
    formData.append("Issued_by", formElements.Issued_by.value);
    formData.append(
      "Register_Serial_number",
      formElements.Register_Serial_number.value
    );
    formData.append("Type", "Patent");

    try {
      const response = await fetch(`${urlLink}/employee/patent/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        swal(
          t.success.charAt(0).toUpperCase() + t.success.slice(1),
          t.newpatentinformationaddedsuccessfully,
          "success"
        );
        handleCloseWindow();
        handleUpdate();
      } else {
        const errorData = await response.json();
        console.error("Error Response:", errorData);
        swal(
          t.error.charAt(0).toUpperCase() + t.error.slice(1),
          errorData.message || t.failedtoaddpatentinformation,
          "error"
        );
      }
    } catch (error) {
      console.error("Catch Error:", error);
      swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), error.message, "error");
    }
  };

  const handleCloseWindow = () => {
    setIsWindowOpen(false);
  };
  const renderTableRows = () => {
    return content.map((content) => (
      <tr key={content.id}>
        <td>{content.id}</td>
        <td>{content.date}</td>
        <td>{content.amount}</td>
        <td>{id}</td>
        <td>
          {content.counter_party_data.firstname}{" "}
          {content.counter_party_data.surname}
        </td>
        <td>
          {data?.Passport[0]?.PassportSerie}
          {data?.Passport[0]?.PassportNumber}
        </td>
        <td>{t.patent}</td>
        <td>{data?.Patent[0]?.DocumentSerie}</td>
        <td>{data?.Patent[0]?.DocumentNo}</td>
        <td>{data?.Patent[0]?.IssueDate}</td>
        <td>
          {content?.file ? (
            <a
              style={{ textDecoration: "underline" }}
              href={content.file}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t.seeCheque}
            </a>
          ) : (
            t.noData
          )}
        </td>
      </tr>
    ));
  };
  const [datacheck, setDatacheck] = useState(true)
  useEffect(() => {
    if(!data?.Patent[0]){
      setDatacheck(false)
    }
    for(let i in data?.Patent[0]){
      if(typeof data?.Patent[0]?.[i] === "string" && data?.Patent[0]?.[i] == ""){
        setDatacheck(false)
      }
      else if(data?.Patent[0]?.[i] === null&& i != "file") setDatacheck(false)
      else if(data?.Patent[0]?.[i] instanceof  Object && Object.keys(data?.Patent[0]?.[i]).length == 0){
        setDatacheck(false)
      }
      else setDatacheck(true)
    }
  }, [data?.Patent[0]])
  return (
    <div style={!datacheck ? {background: "rgb(223,223,223)"} : null} className="faq-container2">
      <div onClick={() => handleToggle("patent")} className="faq__t-wrapper">
        <h5 className="toggle-title">{t.patentInformation}</h5>
        <p className="faq__header-seria">
          {data?.Patent && data?.Patent.length > 0
            ? `${data?.Patent[0].Register_Serial_number}`
            : t.noPatentInformation}
        </p>
      </div>
      <div
        className={`collapsible-content ${
          openSection === "patent" ? "open" : ""
        }`}
      >
        {data?.Patent && data?.Patent.length > 0 ? (
          <div className="faq-details">
            <div className="faq__top-wrapper">
              <p className="faq__section-title">{t.patent}</p>
              <span className="faq__line"></span>
              <div className="faq__wrapper">
                <p className="faq__topsubheader">{t.issuedBy}</p>
                <p className="faq__country-code">{data?.Patent[0].Issued_by}</p>
              </div>
              <span className="faq__line"></span>
              <div className="faq__wrapper">
                <p className="faq__topsubheader">{t.documentNumber}</p>
                <p className="faq__country-code">
                  {data?.Patent[0].DocumentNo}
                </p>
              </div>
            </div>
            <div className=" faq__patent-wrapper">
              <div className="faq__info">
                <div className="faq-field">
                  <span className="field-label">{t.nameSurname}:</span>{" "}
                  <p className="field__data">
                    {data?.Patent[0].Employee_data.firstname}{" "}
                    {data?.Patent[0].Employee_data.surname}
                  </p>
                </div>
                <div className="faq__patentWrapper">
                  <div className="faq-field">
                    <span className="field-label">{t.jobTitle}:</span>{" "}
                    <p className="field__data">{data?.Patent[0].Job_Title}</p>
                  </div>
                  <div className="faq-field">
                    <span className="field-label">{t.specialmarks}:</span>{" "}
                    <p className="field__data">
                      {data?.Patent[0].SpecialMarks}
                    </p>
                  </div>
                </div>
                <div className="faq-field">
                  <span className="field-label">{t.validterritories}:</span>{" "}
                  <p className="field__data">
                  {
                      data?.Patent[0].ValidTerritories_data?.[
                        "SubjectOfRF_" + language.toUpperCase()
                      ]
                    }
                  </p>
                </div>
                <div className="faq-field">
                  <span className="field-label">{t.registerSerialNumber}:</span>{" "}
                  <p className="field__data">
                    {data?.Patent[0].Register_Serial_number}
                  </p>
                </div>

                <button
                  className="btn btn-secondary mt-2 me-2 faq__edit-btn"
                  onClick={handleEditClick}
                >
                  <i className="fa fa-pencil"></i> {t.edit}
                </button>

                <button
                  className=" btn btn-secondary mt-2 me-2 faq__pdf-btn"
                  onClick={handlePdfClick}
                >
                  PDF
                </button>
                <button
                  className=" btn btn-secondary mt-2 faq__pdf-btn"
                  onClick={handleShowModal}
                >
                  {t.cheque}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-data">
            {dataFilter === "latest" ? (
              <div className="no-data-wrapper">
                <p className="no-data">{t.noData}</p>
                <div className="faq__add-btn-wrapper">
                  <Button className="faq__edit-btn" onClick={handleAddClick}>
                    {t.attachment}
                  </Button>
                </div>
              </div>
            ) : (
              <p>{t.noData}</p>
            )}
          </div>
        )}
      </div>
      <Modal show={showModal} size="xl" onHide={() => setShowModal(false)}>
        <div
          className="card-header"
          style={{ padding: "20px", paddingLeft: "30px" }}
        >
          <h4 className="card-title">
            {t.patent} {t.cheque}
          </h4>
        </div>
        <div className="card-body" style={{ padding: "20px" }}>
          <div className="table-responsive">
            <table className="table table-responsive-md card-table transactions-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t.date}</th>
                  <th>{t.amount}</th>
                  <th>{t.employee} ID</th>
                  <th>{t.employee}</th>
                  <th>{t.passportNumber}</th>
                  <th>{t.documentType}</th>
                  <th>{t.documentSerie}</th>
                  <th>{t.documentNumber}</th>
                  <th>{t.issueDate}</th>
                  <th>{t.action}</th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
        </div>
      </Modal>
      {isWindowOpen && (
        <div className="overlay show" onClick={handleCloseWindow}>
          <div
            className="sliding-window show"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-btn" onClick={handleCloseWindow}>
              &times;
            </span>
            {windowContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default PatentFAQ;