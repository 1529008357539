const numberSeperate = (num) => {
    // Split the number into integer and fractional parts
    const [integerPart, fractionalPart] = num.toString().split(".");
  
    // Format the integer part (add spaces every 3 digits)
    const formattedInteger = integerPart.split("").reverse().reduce((acc, curr, index) => {
      if (index > 0 && index % 3 === 0) {
        acc.push(" "); // Add space every 3 digits
      }
      acc.push(curr);
      return acc;
    }, []).reverse().join("");
  
    // Handle fractional part: ensure it has exactly two digits
    let formattedFraction = '';
    if (fractionalPart) {
      formattedFraction = fractionalPart.slice(0, 2);  // Take only the first two digits
    }
  
    // If fractional part is less than 2 digits, pad it with zeroes
    if (formattedFraction.length < 2) {
      formattedFraction = formattedFraction.padEnd(2, '0');
    }
  
    // If there was a fractional part, append it after the formatted integer part using a comma
    return formattedFraction ? `${formattedInteger},${formattedFraction}` : formattedInteger;
  };
  
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  export { numberSeperate, capitalizeFirstLetter }