import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import { useLanguage } from "../../../context/LanguageContext";
import translations from "../../../translation/translation";
import { useNavigate } from "react-router-dom";
import "./BudgetItem.css";
import { getUserPermissions } from "../../components/Permissions/getUserPermissions";
import { Button, Form } from "react-bootstrap";
import CountrySearch from "../../components/countries-search/budgetItem/BudgetItem";
import { FaCircleInfo } from "react-icons/fa6";
import { numberSeperate } from '../../../utils/Numberformat'
const BudgetItem = () => {
  const { id } = useParams();
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.access;
  const { language } = useLanguage();
  const history = useNavigate();
  const t = translations[language];
  const [budgetDetails, setBudgetDetails] = useState(null);
  const [newRow, setNewRow] = useState(false);
  const [newRowData, setNewRowData] = useState({
    Discipline: "",
    BudgetCode: "",
    PrimaveraCode: "",
    JobTitle: "",
    DocumentType: "",
    Currency: "",
    UoM: "",
    StartOfWorkDate: "",
    EndOfWorkDate: "",
    EmpQty: "",
    EmpNetSalary: "",
  });
  const userPermissions = getUserPermissions("budgets");
  const [disciplineOptions, setDisciplineOptions] = useState([]);
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [unitOfMeasureOptions, setUnitOfMeasureOptions] = useState([]);
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [windowContent, setWindowContent] = useState(null);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const urlLink = process.env.REACT_APP_API_URL;
  const [taxesExplanationBox, setTaxesExplanationBox] = useState(false)
  const fetchJobTitleOptions = () => {
    const url = `${urlLink}/gendt/budget-details/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${t.httperrorstatus}${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setJobTitleOptions(
          data.Selection.JobTitle.map((item) => {
            return {
              label: item[`JobTitle${language.toUpperCase()}`],
              value: item.id,
              EmpClass: item.EmpClass_data[`EmpClass${language.toUpperCase()}`],
              EmpLevel: item.EmpLevel_data[`EmpLevel${language.toUpperCase()}`],
            };
          })
        );
        setUnitOfMeasureOptions(
          data.Selection.UoM.map((item) => ({
            label: item[`UoM_${language.toUpperCase()}`],
            value: item.id,
          }))
        );
        setCurrencyOptions(
          data.Selection.Currency.map((item) => ({
            label: item[`Currency${language.toUpperCase()}`],
            value: item.id,
          }))
        );

        setDisciplineOptions(
          data.Selection.Discipline.map((item) => ({
            label: item[`Discipline${language.toUpperCase()}`],
            value: item.id,
          }))
        );

        setIsOptionsLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching job title options:", error);
      });
  };

  useEffect(() => {
    fetchDocumentTypeOptions();
  }, []);
  useEffect(() => {
    let start = newRowData["StartOfWorkDate"].split("-")
    let end = newRowData["EndOfWorkDate"].split("-")
    if((start.join("").length == 8 && end.join("").length == 8)&&(start[2]*1 > end[2]*1)){
      start.splice(2, 1)
      setNewRowData((prevData) => ({
        ...prevData,
        ["StartOfWorkDate"]: [...start,end[2]].join("-"),
      }));
    }
  }, [newRowData["StartOfWorkDate"], newRowData["EndOfWorkDate"]])
  useEffect(() => {
    let start = newRowData["StartOfWorkDate"].split("-")
    let end = newRowData["EndOfWorkDate"].split("-")
    if((start.join("").length == 8 && end.join("").length == 8)&&(start[2]*1 == end[2]*1)&&(start[1]*1 > end[1]*1)){
      start.splice(1, 1)
      setNewRowData((prevData) => ({
        ...prevData,
        ["StartOfWorkDate"]: [start[0],end[1], start[1]].join("-"),
      }));
    }
  }, [newRowData["StartOfWorkDate"], newRowData["EndOfWorkDate"]])
  useEffect(() => {
    let start = newRowData["StartOfWorkDate"].split("-")
    let end = newRowData["EndOfWorkDate"].split("-")
    if((start.join("").length == 8 && end.join("").length == 8)&&(start[1]*1 == end[1]*1)&&(start[0]*1 > end[0]*1)){
      start.splice(0, 1)
      setNewRowData((prevData) => ({
        ...prevData,
        ["StartOfWorkDate"]: [end[0],start[0], start[1]].join("-"),
      }));
    }
  }, [newRowData["StartOfWorkDate"], newRowData["EndOfWorkDate"]])
  useEffect(() => {
    fetchJobTitleOptions();
  }, []);
  const [searchCountryInputPost, setSearchCountryInputPost] = useState('')
  const [countriesDataPost, setCountriesDataPost] = useState(jobTitleOptions || [])
  const [countrySearchBoxStatePost, setCountrySearchBoxStatePost] = useState(false)
  const [countryStyleIndexPost, setCountryStyleIndexPost] = useState(null)
  useEffect(() => {
    setCountriesDataPost(jobTitleOptions.filter(e=>e["label"].slice(0, searchCountryInputPost.length).toLowerCase() == searchCountryInputPost.trim().toLowerCase()))
    newRowData.label = searchCountryInputPost.trim('').toLowerCase()
  }, [searchCountryInputPost])
  useEffect(() => {
    setCountriesDataPost(jobTitleOptions)
  }, [countrySearchBoxStatePost])
  const fetchDocumentTypeOptions = () => {
    const url = `${urlLink}/gendt/doctype/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${t.httperrorstatus}${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setDocumentTypeOptions(
          data.Response.map((item) => ({
            label: item[`DocumentType${language.toUpperCase()}`],
            value: item.id,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching document type options:", error);
        // Handle error
      });
  };

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history("/login");
      return;
    }

    const url = `${urlLink}/gendt/budget-details/${id}/`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history("/login");
          throw new Error(`${t.httperrorstatus}${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.Response);

        setBudgetDetails(data.Response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          t.error.charAt(0).toUpperCase() + t.error.slice(1),
          t.therewasissuewithfetchoperation + error.message,
          "error"
        );
      });
  }, [id, token, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!budgetDetails) {
    return <div>{t.loading}...</div>;
  }

  const handleBackClick = () => {
    history(-1);
  };

  const handleAddClick = () => {
    setNewRow(true);
    fetchJobTitleOptions();
    setSearchCountryInputPost("")
  };

  const handleCancelClick = () => {
    setNewRow(false);
    setNewRowData({
      Discipline: "",
      BudgetCode: "",
      PrimaveraCode: "",
      JobTitle: "",
      DocumentType: "",
      Currency: "",
      UoM: "",
      StartOfWorkDate: "",
      EndOfWorkDate: "",
      EmpQty: "",
      EmpNetSalary: "",
    });
  };
  
  const formatDateToDDMMYYYY = (date) => {
    if (!date) return "";

    const dateObj = new Date(date);

    if (isNaN(dateObj)) return "";

    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = dateObj.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const fetchBudgetDetails = () => {
    const url = `${urlLink}/gendt/budget-details/${id}/`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${t.httperrorstatus}${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setBudgetDetails(data.Response);
      })
      .catch((error) => {
        console.error("Error fetching budget details:", error);
        swal(
          t.error.charAt(0).toUpperCase() + t.error.slice(1),
          t.therewasanissuefetchingthebudgetdetails + error.message,
          "error"
        );
      });
  };

  const handleSaveClick = () => {
    const url = `${urlLink}/gendt/budget-details/`;

    const formattedStartOfWorkDate = formatDateToDDMMYYYY(
      newRowData.StartOfWorkDate
    );
    const formattedEndOfWorkDate = formatDateToDDMMYYYY(
      newRowData.EndOfWorkDate
    );

    const payload = {
      Discipline: parseInt(newRowData.Discipline, 10),
      Currency: parseInt(newRowData.Currency, 10),
      UoM: parseInt(newRowData.UoM, 10),
      LegDocumentType: parseInt(newRowData.DocumentType, 10),
      JobTitle: "",
      BudgetCode: newRowData.BudgetCode,
      PrimaveraCode: newRowData.PrimaveraCode,
      StartOfWorkDate: newRowData.StartOfWorkDate.split("-").join("."),
      EndOfWorkDate: newRowData.EndOfWorkDate.split("-").join("."),
      EmpQty: parseInt(newRowData.EmpQty, 10),
      EmpNetSalary: parseFloat(newRowData.EmpNetSalary),
      Budget_ID: parseInt(id, 10),
    };
    payload.JotTitle=jobTitleOptions.find(a => a.label.toLowerCase() == searchCountryInputPost.trim().toLowerCase())?.value || "";
    if(isNaN(payload.JotTitle)){ swal(
      t.error.charAt(0).toUpperCase() + t.error.slice(1),
     t.incorrectjobtitle,
      "error"
    );
  return 
  }
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${t.httperrorstatus}${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && data["Data is successfully saved"]) {
          // Re-fetch the updated budget details
          fetchBudgetDetails();
          // Reset form and other states
          setNewRow(false);
          setNewRowData({
            Discipline: "",
            BudgetCode: "",
            PrimaveraCode: "",
            JobTitle: "",
            DocumentType: "",
            Currency: "",
            UoM: "",
            StartOfWorkDate: "",
            EndOfWorkDate: "",
            EmpQty: "",
            EmpNetSalary: "",
          });
          swal(
            t.success.charAt(0).toUpperCase() + t.success.slice(1),
            t.newbudgetitemaddedsuccessfully,
            "success"
          );
        } else {
          throw new Error(t.unexpectedresponseformat);
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        swal(
          t.error.charAt(0).toUpperCase() + t.error.slice(1),
          t.therewasanissuewiththesaveoperationpleasetryagainlater,
          "error"
        );
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const old = newRowData[name];
    const arr = value.split("")
    if(arr.length == 10){
      if(Number(arr[3]+arr[4]) > 12){
        arr[3]="1"
        arr[4]="2"
      }
      if(Number(arr[0]+arr[1]) > 31 && Number(arr[3]+arr[4]) == 1){
        arr.shift()
        arr.shift()
        arr.unshift("31")
      }
      if(Number(arr[0]+arr[1]) > 29 && Number(arr[3]+arr[4]) == 2){
        arr.shift()
        arr.shift()
        arr.unshift("29")
      }
      if(Number(arr[0]+arr[1]) > 31 && Number(arr[3]+arr[4]) == 3){
        arr.shift()
        arr.shift()
        arr.unshift("31")
      }
      if(Number(arr[0]+arr[1]) > 30 && Number(arr[3]+arr[4]) == 4){
        arr.shift()
        arr.shift()
        arr.unshift("30")
      }
      if(Number(arr[0]+arr[1]) > 31 && Number(arr[3]+arr[4]) == 5){
        arr.shift()
        arr.shift()
        arr.unshift("31")
      }
      if(Number(arr[0]+arr[1]) > 30 && Number(arr[3]+arr[4]) == 6){
        arr.shift()
        arr.shift()
        arr.unshift("30")
      }
      if(Number(arr[0]+arr[1]) > 31 && Number(arr[3]+arr[4]) == 7){
        arr.shift()
        arr.shift()
        arr.unshift("31")
      }
      if(Number(arr[0]+arr[1]) > 31 && Number(arr[3]+arr[4]) == 8){
        arr.shift()
        arr.shift()
        arr.unshift("31")
      }
      if(Number(arr[0]+arr[1]) > 30 && Number(arr[3]+arr[4]) == 9){
        arr.shift()
        arr.shift()
        arr.unshift("30")
      }
      if(Number(arr[0]+arr[1]) > 31 && Number(arr[3]+arr[4]) == 10){
        arr.shift()
        arr.shift()
        arr.unshift("31")
      }
      if(Number(arr[0]+arr[1]) > 30 && Number(arr[3]+arr[4]) == 11){
        arr.shift()
        arr.shift()
        arr.unshift("30")
      }
      if(Number(arr[0]+arr[1]) > 31 && Number(arr[3]+arr[4]) == 12){
        arr.shift()
        arr.shift()
        arr.unshift("31")
      }
    }
    if(name == "StartOfWorkDate" || name == "EndOfWorkDate"){
      if(!isNaN(value.split("-").join("")) && value.split("").join("").length <= 10 && ((old === e.target.value.slice(0, old.length)) || e.target.value === old.slice(0, e.target.value.length))){
        setNewRowData((prevData) => ({
          ...prevData,
          [name]: (arr.join("").split("").join("").length == 2 && old.length < arr.join("").split("").join("").length)  || (arr.join("").split("").join("").length == 5 && old.length < arr.join("").split("").join("").length) ? arr.join("")+"-" : arr.join(""),
        }));
      }
    }
    else setNewRowData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // const handleJobTitleChange = (e) => {
  //   const { value } = e.target;
  //   const selectedJobTitle = jobTitleOptions.find(
  //     (option) => option.value === parseInt(value)
  //   );
  //   setNewRowData((prevData) => ({
  //     ...prevData,
  //     JobTitle: value,
  //     EmpClass: selectedJobTitle ? selectedJobTitle.EmpClass : "",
  //     EmpLevel: selectedJobTitle ? selectedJobTitle.EmpLevel : "",
  //   }));
  // };

  const handleDeleteClick = (itemId) => {
    const url = `${urlLink}/gendt/budget-details/${itemId}/`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    swal({
      title: t.areyousure,
      text: t.onceudeleteituwillnotbeabletorecoverthisbudgetitem,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(url, requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`${t.httperrorstatus}${response.status}`);
            }
            // Update the state to remove the deleted item
            setBudgetDetails((prevDetails) =>
              prevDetails.filter((item) => item.id !== itemId)
            );
            swal(t.proofyourbudgetitemhasbeendeleted, {
              icon: "success",
            });
          })
          .catch((error) => {
            console.error("Error deleting data:", error);
            swal(
              t.error.charAt(0).toUpperCase() + t.error.slice(1),
              t.therewasanissuewiththedeleteoperationpleasetryagainlater,
              "error"
            );
          });
      }
    });
  };

  const formatDateToShow = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split(".");
    return `${year}-${month}-${day}`;
  };

  const handleEditSubmit = async (e, itemId) => {
    e.preventDefault();

    const formElements = e.target.elements;

    const formattedStartOfWorkDate = formatDateToDDMMYYYY(
      formElements.StartDate.value
    );
    const formattedEndOfWorkDate = formatDateToDDMMYYYY(
      formElements.EndDate.value
    );

    const payload = {
      Discipline: parseInt(formElements.Discipline.value, 10),
      Currency: parseInt(formElements.currency.value, 10),
      UoM: parseInt(formElements.UoM.value, 10),
      LegDocumentType: parseInt(formElements.LegDocumentType.value, 10),
      JotTitle: "",
      StartOfWorkDate: formattedStartOfWorkDate || null,
      EndOfWorkDate: formattedEndOfWorkDate || null,
      EmpQty: parseInt(formElements.EmpQty.value, 10),
      EmpNetSalary: parseFloat(formElements.EmpNetSalary.value),
      Budget_ID: parseInt(id, 10),
    };
    payload.JotTitle=jobTitleOptions.find(a => a.label.toLowerCase() == formElements.JobTitle.value.trim().toLowerCase())?.value || "";
    if(isNaN(payload.JotTitle)){ swal(
      t.error.charAt(0).toUpperCase() + t.error.slice(1),
     t.incorrectjobtitle,
      "error"
    );
  return 
  }
    const apiUrl = `${urlLink}/gendt/budget-details/${itemId}/`;

    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        swal("error", "Error occured", "error");
      } else {
        const responseData = await response.json();
        swal("success", "Update data successfully", "success");
        fetchBudgetDetails();
        setIsWindowOpen(false);
      }
    } catch (error) {
      console.error("Error during PUT request:", error);
      swal("error", "Error occured", "error");
    }
  };

  const handleEditRow = (item) => {
    const EditContent = (
      <div className="edit-form">
        <h3>{t.editmydata}</h3>
        <Form onSubmit={(e) => handleEditSubmit(e, item.id)}>
          <Form.Group controlId="formDiscipline">
            <Form.Label className="input__label">{t.selectdiscipline}</Form.Label>
            <Form.Control
            className="input_height_default"
              as="select"
              name="Discipline"
              defaultValue={item?.Discipline_data?.id}
            >
              <option value="">{t.selectdiscipline}</option>
              {disciplineOptions.map((discipline) => (
                <option key={discipline.value} value={discipline.value}>
                  {discipline.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* <Form.Group controlId="formBudgetCode">
            <Form.Label className="input__label">{t.enterbudgetcode}:</Form.Label>
            <Form.Control
            className="input_height_default"
              type="text"
              name="BudgetCode"
              defaultValue={item?.BudgetCode}
              className="edit__input"
            />
          </Form.Group>

          <Form.Group controlId="formPrimaveraCode">
            <Form.Label className="input__label">{t.enterprimaveracode}:</Form.Label>
            <Form.Control
            className="input_height_default"
              type="text"
              name="PrimaveraCode"
              className="edit__input"
              defaultValue={item?.PrimaveraCode}
            />
          </Form.Group> */}

          <Form.Group controlId="formJobTitle">
            <Form.Label className="input__label">{t.selectjobtitle}</Form.Label>
            <CountrySearch name={"JobTitle"} data={item} t={t} countries={jobTitleOptions}/>
          </Form.Group>

          <Form.Group controlId="formLegDocumentType">
            <Form.Label className="input__label">{t.documentType}</Form.Label>
            <Form.Control
            className="input_height_default"
              as="select"
              name="LegDocumentType"
              defaultValue={item?.LegDocumentType_data?.id}
            >
              <option value="">{t.selectdocumenttype}</option>
              {documentTypeOptions.map((jobTitle) => (
                <option key={jobTitle.value} value={jobTitle.value}>
                  {jobTitle.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formCurrency">
            <Form.Label className="input__label">{t.currency}</Form.Label>
            <Form.Control
            className="input_height_default"
              as="select"
              name="currency"
              defaultValue={item?.Currency_data?.id}
            >
              <option value="">{t.selectcurrency}</option>
              {currencyOptions.map((jobTitle) => (
                <option key={jobTitle.value} value={jobTitle.value}>
                  {jobTitle.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formUoM">
            <Form.Label className="input__label">{t.unitOfMeasure}</Form.Label>
            <Form.Control
            className="input_height_default"
              as="select"
              name="UoM"
              defaultValue={item?.UoM_data?.id}
            >
              <option value="">{t.selectunitofmeasure}</option>
              {unitOfMeasureOptions.map((uom) => (
                <option key={uom.value} value={uom.value}>
                  {uom.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formStartDate">
            <Form.Label className="input__label">{t.startofworkdate}</Form.Label>
            <Form.Control
            className="input_height_default"
              type="date"
              name="StartDate"
              defaultValue={formatDateToShow(item?.StartOfWorkDate)}
              max={formatDateToShow(item?.EndOfWorkDate)}
              className="edit__input"
            />
          </Form.Group>

          <Form.Group controlId="formEndDate">
            <Form.Label className="input__label">{t.endofworkdate}</Form.Label>
            <Form.Control
            className="input_height_default"
              type="date"
              name="EndDate"
              min={formatDateToShow(item?.StartOfWorkDate)}
              defaultValue={formatDateToShow(item?.EndOfWorkDate)}
              className="edit__input"
            />
          </Form.Group>

          <Form.Group controlId="formEmpQty">
            <Form.Label className="input__label">{t.employeeQuantity}:</Form.Label>
            <Form.Control
            className="input_height_default"
              type="number"
              name="EmpQty"
              defaultValue={item?.EmpQty}
              className="edit__input"
            />
          </Form.Group>

          <Form.Group controlId="formEmpNetSalary">
            <Form.Label className="input__label">{t.employeeSalary}:</Form.Label>
            <Form.Control
            className="input_height_default"
              type="number"
              name="EmpNetSalary"
              defaultValue={item?.EmpNetSalary}
              className="edit__input"
            />
          </Form.Group>

          <div className="edit__btn-wrapper mt-2">
            <Button className="edit__btn btn_height_default" type="submit">
              {t.savechanges}
            </Button>
          </div>
        </Form>
      </div>
    );
    setWindowContent(EditContent);
    setIsWindowOpen(true);
  };

  const handleCloseWindow = () => {
    setIsWindowOpen(false);
  };

  return (
    <>
      <PageTitle activeMenu={t.budgetdetails} motherMenu={t.budgets} />
      <div style={{overflowX: "auto", height: "500px"}} className=" ms-0 me-0">
        <h2>{t.budgetDetails}</h2>
        <div className="card-body p-0">
          <div>
            <table className="budget-table display w-100">
              <thead>
                <tr>
                  <th className="bg-secondary text-nowrap">{t.discipline}</th>
                  {/* <th className="bg-secondary text-nowrap">{t.budgetCode}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.primaveraCode}
                  </th> */}
                  <th className="bg-secondary text-nowrap">{t.jobTitle}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.employeeClass}
                  </th>
                  <th className="bg-secondary text-nowrap">
                    {t.employeeLevel}
                  </th>
                  <th className="bg-secondary text-nowrap">{t.documentType}</th>
                  <th className="bg-secondary text-nowrap">{t.currency}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.unitOfMeasure}
                  </th>
                  <th className="bg-secondary text-nowrap">{t.startDate}</th>
                  <th className="bg-secondary text-nowrap">{t.endDate}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.employeeQuantity}
                  </th>
                  <th className="bg-secondary text-nowrap">
                    {t.employeeSalary}
                  </th>
                  <th className="bg-secondary text-nowrap">{t.salary}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.dayDifference}
                  </th>
                  <th className="bg-secondary text-nowrap">
                    {t.monthDifference}
                  </th>
                  <th className="bg-secondary text-nowrap">
                    {t.yearDifference}
                  </th>
                  <th className="bg-secondary text-nowrap">{t.taxes}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.legalExpenses}
                  </th>
                  {(userPermissions.canEdit || userPermissions.canDelete) && (
                    <th className="bg-secondary text-nowrap">{t.actions}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {console.log(budgetDetails)}
                {budgetDetails.map((item) => (
                  <tr key={item.id}>
                    <td>
                      {
                        item.Discipline_data?.[
                          `Discipline${language.toUpperCase()}`
                        ]
                      }
                    </td>
                    {/* <td>{item.BudgetCode}</td>
                    <td>{item.PrimaveraCode}</td> */}
                    <td>
                      {item.JotTitle_data[`JobTitle${language.toUpperCase()}`]}
                    </td>
                    <td>
                      {
                        item.JotTitle_data.EmpClass_data[
                          `EmpClass${language.toUpperCase()}`
                        ]
                      }
                    </td>
                    <td>
                      {
                        item.JotTitle_data.EmpLevel_data[
                          `EmpLevel${language.toUpperCase()}`
                        ]
                      }
                    </td>
                    <td>
                      {
                        item.LegDocumentType_data[
                          `DocumentType${language.toUpperCase()}`
                        ]
                      }
                    </td>
                    <td>
                      {item.Currency_data[`Currency${language.toUpperCase()}`]}
                    </td>
                    <td>
                      {item.UoM_data[`UoM_Short_${language.toUpperCase()}`]}
                    </td>
                    <td>{item.StartOfWorkDate}</td>
                    <td>{item.EndOfWorkDate}</td>
                    <td>{item.EmpQty}</td>
                    <td>{numberSeperate(item.EmpNetSalary)}</td>
                    <td>{numberSeperate(item.salary)}</td>
                    <td>{item.day_difference}</td>
                    <td>{item.month_difference}</td>
                    <td>{item.year_difference}</td>
                    <td style={{whiteSpace: "nowrap", position: "relative"}} >{numberSeperate(item.taxes)}<i onMouseOver={() => {
                      setTaxesExplanationBox(item?.id)
                    }} onMouseLeave={() => {
                      setTaxesExplanationBox('')
                    }} className="budget-taxes-info"><FaCircleInfo/>{taxesExplanationBox == item.id && <div className="budget-taxes-box"><div></div>
                    <div>
                    <span>
                    {t.ssf_taxes}: {numberSeperate(item.ssf_taxes) || 0}
                    </span>
                    <span>
                    {t.pit_taxes}: {numberSeperate(item.pit_taxes) || 0}
                    </span>
                    </div></div>}</i></td>
                    <td>{numberSeperate(item.legal_expenses)}</td>
                    {(userPermissions.canEdit || userPermissions.canDelete) && (
                      <td>
                        <div className="datab">
                          <button
                            className="btn btn-secondary shadow btn-xs sharp me-1"
                            onClick={() => handleEditRow(item)}
                          >
                            <i className="fa fa-pencil"></i>
                          </button>
                          <button
                            className="btn btn-danger shadow btn-xs sharp"
                            onClick={() => handleDeleteClick(item.id)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
                {newRow && (
                  <tr>
                    <td>
                      {isOptionsLoaded ? (
                        <select
                          name="Discipline"
                          value={newRowData.Discipline}
                          onChange={handleInputChange}
                          className="table-input"
                        >
                          <option value="">{t.selectdiscipline}</option>
                          {disciplineOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name="Discipline"
                          value={newRowData.Discipline}
                          onChange={handleInputChange}
                          className="table-input"
                          placeholder={`${t.loadingoptions}...`}
                          disabled
                        />
                      )}
                    </td>
                    {/* <td>
                      <input
                        type="text"
                        name="BudgetCode"
                        value={newRowData.BudgetCode}
                        onChange={handleInputChange}
                        className="table-input"
                        placeholder={t.enterinput}
                      />
                    </td> */}
                    {/* <td>
                      <input
                        type="text"
                        name="PrimaveraCode"
                        value={newRowData.PrimaveraCode}
                        onChange={handleInputChange}
                        className="table-input"
                        placeholder={t.enterinput}
                      />
                    </td> */}
                    <td>
                           <Form.Control style={{width: "300px", background: "none"}} data-employee-search="search"
              type="text"
              name={"JobTitle"}
              value={searchCountryInputPost}
              className="table-input"
              onBlur={() => {
                setTimeout(() => {
                  setCountrySearchBoxStatePost(false)
                }, 300)
              }}
              onInput={e => {
                setSearchCountryInputPost(e.target.value)
                setCountrySearchBoxStatePost(true)
              }}
              onClick={() => {
                setCountrySearchBoxStatePost(true)
              }}
              placeholder={t.enterjobtitle}
            />
            {countrySearchBoxStatePost && <div data-employee-search="search" className="countries_search__wrapper">
              <div className="countries_container">
              {countriesDataPost.map((e) => 
             <div onMouseOver={() => {
              setCountryStyleIndexPost(e.value)
            }} onClick={() => {
             setSearchCountryInputPost(e.label)
             setCountrySearchBoxStatePost(false)
             }} style={countryStyleIndexPost==e.value ? {background:"purple", color: "#fff"} : null} className="country_search_card employee_add_country_search_card" key={e.id}>{e["label"]}</div>
              )}
            </div>
            </div>}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="EmpClass"
                        value={newRowData.EmpClass} // Ensure EmpClass value is passed here
                        className="table-input"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="EmpLevel"
                        value={newRowData.EmpLevel}
                        className="table-input"
                        disabled
                      />
                    </td>
                    <td>
                      {isOptionsLoaded ? (
                        <select
                          name="DocumentType"
                          value={newRowData.DocumentType}
                          onChange={handleInputChange}
                          className="table-input"
                        >
                          <option value="">{t.selectdocumenttype}</option>
                          {documentTypeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name="DocumentType"
                          value={newRowData.DocumentType}
                          onChange={handleInputChange}
                          className="table-input"
                          placeholder={`${t.loadingoptions}...`}
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      {isOptionsLoaded ? (
                        <select
                          name="Currency"
                          value={newRowData.Currency}
                          onChange={handleInputChange}
                          className="table-input"
                        >
                          <option value="">{t.selectcurrency}</option>
                          {currencyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name="Currency"
                          value={newRowData.Currency}
                          onChange={handleInputChange}
                          className="table-input"
                          placeholder={`${t.loadingoptions}...`}
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      {isOptionsLoaded ? (
                        <select
                          name="UoM"
                          value={newRowData.UoM}
                          onChange={handleInputChange}
                          className="table-input"
                        >
                          <option value="">{t.selectunitofmeasure}</option>
                          {unitOfMeasureOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name="UoM"
                          value={newRowData.UoM}
                          onChange={handleInputChange}
                          className="table-input"
                          placeholder={`${t.loadingoptions}...`}
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="StartOfWorkDate"
                        value={newRowData?.StartOfWorkDate}
                        onChange={handleInputChange}
                        className="table-input"
                        placeholder={`DD.MM.YY`}
                        max={newRowData.EndOfWorkDate.split("-").join(".") || ""}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="EndOfWorkDate"
                        value={newRowData?.EndOfWorkDate}
                        onChange={handleInputChange}
                        className="table-input"
                        placeholder={`DD.MM.YY`}
                        min={newRowData.StartOfWorkDate.split("-").join(".") || ""}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="EmpQty"
                        value={newRowData.EmpQty}
                        onChange={handleInputChange}
                        className="table-input"
                        placeholder={t.enterinput}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="EmpNetSalary"
                        value={newRowData.EmpNetSalary}
                        onChange={handleInputChange}
                        className="table-input"
                        placeholder={t.enterinput}
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="button-container d-flex align-items-center justify-content-center">
          {userPermissions.canDelete && (
            <div>
              {newRow ? (
                <>
                  <button onClick={handleSaveClick} className="btn btn-success">
                    {t.savechanges}
                  </button>
                  <button
                    onClick={handleCancelClick}
                    className="btn btn-danger"
                  >
                    {t.cancel}
                  </button>
                </>
              ) : (
                <button onClick={handleAddClick} className="btn btn-primary">
                  {t.add}
                </button>
              )}
            </div>
          )}
          <button onClick={handleBackClick} className="btn btn-secondary m-0">
            {t.back}
          </button>
        </div>
      </div>

      {isWindowOpen && (
        <div className="overlay show" onClick={handleCloseWindow}>
          <div
            className="sliding-window show"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-btn" onClick={handleCloseWindow}>
              &times;
            </span>
            {windowContent}
          </div>
        </div>
      )}
    </>
  );
};

export default BudgetItem;