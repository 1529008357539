import React, { useEffect, useState, useRef } from "react";
import "../styles.css";
import swal from "sweetalert";
import { Row, Col, Form, Button, Navbar, Nav } from "react-bootstrap";
import { fetchCitizenship } from "../../../components/apiData/apiData";
import accountImg from "../../../../images/account.jpg";
import CountrySearch from "../../../components/countries-search/passport/country-search-edit/CountrySearch";
import CountrySearchPost from "../../../components/countries-search/passport/country-search-add/CountrySearch";
const PassportFAQ = ({
  data = { Passport: [] },
  openSection,
  handleToggle,
  t,
  language,
  token,
  handleUpdate,
  id,
  dataFilter,
}) => {
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [windowContent, setWindowContent] = useState(null);
  const [countries, setCountries] = useState([]);
  const urlLink = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const getCountries = async () => {
      const data = await fetchCitizenship(token);
      setCountries(data);
    };

    getCountries();
  }, [token]);

  const formatDateForInput = (dateString) => {
    const [day, month, year] = dateString.split(".");
    return `${year}-${month}-${day}`;
  };

  const handlePdfClick = () => {
    const file = data.Passport[0]?.file;

    const cleanFileUrl = file?.split("?")[0];

    let content;
    if (cleanFileUrl?.endsWith(".pdf")) {
      content = (
        <iframe
          src={file}
          width="100%"
          height="100%"
          title="PDF Viewer"
          frameBorder="0"
          allowFullScreen
        />
      );
    } else {
      content = (
        <img
          className="sliding-image"
          src={file || accountImg}
          alt="PDF or Image"
        />
      );
    }

    setWindowContent(content);
    setIsWindowOpen(true);
  };

  const inputPutEl = useRef()
  const handleEditClick = () => {
    const editContent = (
      <div className="edit-form">
        <h3>{t.editpassportinformation}</h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formCitizenshipData">
            <Form.Label className="input__label">{t.nationality}:</Form.Label>
            <CountrySearch name={"citizenship"} t={t} language={language} data={data} countries={countries}/>
          </Form.Group>

          <Form.Group controlId="formIssuedByData">
            <Form.Label className="input__label">{t.issuedBy}:</Form.Label>
            <Form.Control
              type="text"
              name="IssuedBy"
              defaultValue={data?.Passport[0]?.IssuedBy || ""}
              className="edit__input input_height_default"
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="formPassportSerie">
            <Form.Label className="input__label">{t.passportserie}:</Form.Label>
            <Form.Control
              type="text"
              name="PassportSerie"
              defaultValue={data?.Passport[0]?.PassportSerie || ""}
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formPassportNumber">
            <Form.Label className="input__label">{t.passportNumber}:</Form.Label>
            <Form.Control
              type="text"
              name="PassportNumber"
              defaultValue={data?.Passport[0]?.PassportNumber || ""}
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formCountryPersNo">
            <Form.Label className="input__label">{t.countrypersnumber}:</Form.Label>
            <Form.Control
              type="text"
              name="CountryPersNo"
              defaultValue={data?.Passport[0]?.CountryPersNo || ""}
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formLetter">
            <Form.Label className="input__label">{t.letter}:</Form.Label>
            <Form.Control
              as="select"
              name="Letter"
              defaultValue={data?.Passport[0]?.Letter || ""}
              className="edit__input input_height_default"
            >
              <option value="Latin">{t.latin}</option>
              <option value="Cyrillic">{t.cyrillic}</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formValidityDate">
            <Form.Label className="input__label">{t.dateOfIssue}:</Form.Label>
            <Form.Control
              type="date"
              name="ValidityDate"
              defaultValue={
                formatDateForInput(data?.Passport[0]?.ValidityDate) || ""
              }
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formIssueDate">
            <Form.Label className="input__label">{t.dateOfExpiry}:</Form.Label>
            <Form.Control
              type="date"
              name="IssueDate"
              defaultValue={
                formatDateForInput(data?.Passport[0]?.IssueDate) || ""
              }
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formFileUpload">
            <Form.Label className="input__label">{t.upload} (PDF/Image):</Form.Label>
            <Form.Control
              type="file"
              name="File"
              accept=".pdf, image/*"
              className="edit__input input_file_height_default"
            />
          </Form.Group>

          <div className="edit__btn-wrapper mt-2">
            <Button className="edit__btn btn_height_default" type="submit">
              {t.save}
            </Button>
          </div>
        </Form>
      </div>
    );
    setWindowContent(editContent);
    setIsWindowOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const formElements = e.target.elements;
    const fileInput = formElements.File;

    formData.append("citizenship", countries.find(a => a["Country"+language.toUpperCase()].toLowerCase() == formElements.citizenship.value.trim().toLowerCase())?.id || swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), t.thecountrywiththisnamenotfound, "error"));
    formData.append("IssuedBy", formElements.IssuedBy.value);
    formData.append("PassportSerie", formElements.PassportSerie.value);
    formData.append("PassportNumber", formElements.PassportNumber.value);
    formData.append("CountryPersNo", formElements.CountryPersNo.value);
    formData.append("Letter", formElements.Letter.value);

    const formattedValidityDate = new Date(formElements.ValidityDate.value)
      .toLocaleDateString("en-GB")
      .replace(/\//g, ".");
    const formattedIssueDate = new Date(formElements.IssueDate.value)
      .toLocaleDateString("en-GB")
      .replace(/\//g, ".");

    formData.append("ValidityDate", formattedValidityDate);
    formData.append("IssueDate", formattedIssueDate);
    if (fileInput.files[0]) {
      formData.append("file", fileInput.files[0]);
    }

    try {
      const response = await fetch(
        `${urlLink}/employee/passport/${data.Passport[0]?.id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        swal(
          t.success.charAt(0).toUpperCase() + t.success.slice(1),
          t.passportinformationupdatedsuccessfully,
          "success"
        );
        handleCloseWindow();
        handleUpdate(); // Trigger a re-fetch after a successful update
      } else {
        const errorData = await response.json();
        throw new Error(
          errorData.message || t.failedtoupdatepassportinformation
        );
      }
    } catch (error) {
      swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), error.message, "error");
    }
  };

  const handleAddClick = () => {
    const addContent = (
      <div className="edit-form">
        <h3>{t.addnewpassportinformation}</h3>
        <Form onSubmit={handleAddSubmit}>
          <Form.Group controlId="formCitizenshipData">
            <Form.Label className="input__label">{t.nationality}:</Form.Label>
            <CountrySearchPost name={"citizenship"} t={t} language={language} data={data} countries={countries}/>
          </Form.Group>

          <Form.Group controlId="formIssuedByData">
            <Form.Label className="input__label">{t.issuedBy}:</Form.Label>
            <Form.Control
              as="input"
              name="IssuedBy"
              defaultValue=""
              className="edit__input input_height_default"
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="formPassportSerie">
            <Form.Label className="input__label">{t.passportserie}:</Form.Label>
            <Form.Control
              type="text"
              name="PassportSerie"
              defaultValue=""
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formPassportNumber">
            <Form.Label className="input__label">{t.passportNumber}:</Form.Label>
            <Form.Control
              type="text"
              name="PassportNumber"
              defaultValue=""
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formCountryPersNo">
            <Form.Label className="input__label">{t.countrypersnumber}:</Form.Label>
            <Form.Control
              type="text"
              name="CountryPersNo"
              defaultValue=""
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formLetter">
            <Form.Label className="input__label">{t.letter}:</Form.Label>
            <Form.Control
              as="select"
              name="Letter"
              defaultValue="Latin"
              className="edit__input input_height_default"
            >
              <option value="Latin">{t.latin}</option>
              <option value="Cyrillic">{t.cyrillic}</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formValidityDate">
            <Form.Label className="input__label">{t.dateOfExpiry}:</Form.Label>
            <Form.Control
              type="date"
              name="ValidityDate"
              defaultValue=""
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formIssueDate">
            <Form.Label className="input__label">{t.dateOfIssue}:</Form.Label>
            <Form.Control
              type="date"
              name="IssueDate"
              defaultValue=""
              className="edit__input input_height_default"
            />
          </Form.Group>

          <Form.Group controlId="formFileUpload">
            <Form.Label className="input__label">{t.upload} (PDF/Image):</Form.Label>
            <Form.Control
              type="file"
              name="File"
              accept=".pdf, image/*"
              className="edit__input input_file_height_default"
            />
          </Form.Group>

          <div className="edit__btn-wrapper mt-2">
            <Button className="edit__btn btn_height_default" type="submit">
              {t.save}
            </Button>
          </div>
        </Form>
      </div>
    );
    setWindowContent(addContent);
    setIsWindowOpen(true);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const formElements = e.target.elements;
    const fileInput = formElements.File;

    formData.append("citizenship", countries.find(a => a["Country"+language.toUpperCase()].toLowerCase() == formElements.citizenship.value.trim().toLowerCase())?.id || swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), t.thecountrywiththisnamenotfound, "error"));
    formData.append("IssuedBy", formElements.IssuedBy.value);
    formData.append("DocumentTypeData", 8);
    formData.append("PassportSerie", formElements.PassportSerie.value);
    formData.append("PassportNumber", formElements.PassportNumber.value);
    formData.append("CountryPersNo", formElements.CountryPersNo.value);
    formData.append("Letter", formElements.Letter.value);
    formData.append("Employee", id);

    const formattedValidityDate = new Date(formElements.ValidityDate.value)
      .toLocaleDateString("en-GB")
      .replace(/\//g, ".");
    const formattedIssueDate = new Date(formElements.IssueDate.value)
      .toLocaleDateString("en-GB")
      .replace(/\//g, ".");

    formData.append("ValidityDate", formattedValidityDate);
    formData.append("IssueDate", formattedIssueDate);
    if (fileInput.files[0]) {
      formData.append("file", fileInput.files[0]);
    }

    try {
      const response = await fetch(`${urlLink}/employee/passport/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        swal(
          t.success.charAt(0).toUpperCase() + t.success.slice(1),
          t.newpassportinformationaddedsuccessfully,
          "success"
        );
        handleCloseWindow();
        handleUpdate(); // Trigger a re-fetch after a successful addition
      } else {
        const errorData = await response.json();
        throw new Error(
          errorData.message || t.failedtoaddpassportinformation
        );
      }
    } catch (error) {
      swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), error.message, "error");
    }
  };

  const handleCloseWindow = () => {
    setIsWindowOpen(false);
  };
  const [datacheck, setDatacheck] = useState(false)
  useEffect(() => {
      if(!data?.Passport[0]){
        setDatacheck(false)
      }
    for(let i in data?.Passport[0]){
      if(typeof data?.Passport[0]?.[i] === "string" && data?.Passport[0]?.[i] === ""){
        setDatacheck(false)
      }
      else if(data?.Passport[0]?.[i] === null && i != "file"){
        setDatacheck(false)
      }
      else if(data?.Passport[0]?.[i] instanceof  Object && Object.keys(data?.Passport[0]?.[i]).length == 0){
        setDatacheck(false)
      }
      else setDatacheck(true)
    }
  }, [data?.Passport[0]])
  const bgCountry = data?.Passport[0]?.citizenship_data?.AlphaCode3;
  const bglower = bgCountry?.toLowerCase();
  return (
    <div style={!datacheck ? {background: "rgb(223,223,223)"} : null} className={`faq-container1 ${bglower}-bg table-responsive`}>
      <div onClick={() => handleToggle("passport")} className="faq__t-wrapper">
        <h5 className="toggle-title">{t.passportInformation}</h5>
        <p className="faq__header-seria">
          {data?.Passport && data?.Passport.length > 0 ? (
            <>{data?.Passport[0].PassportNumber}</>
          ) : (
            t.noPassportInformation
          )}
        </p>
      </div>
      <div
        className={`collapsible-content ${
          openSection === "passport" ? "open" : ""
        }`}
      >
        {data?.Passport && data?.Passport.length > 0 ? (
          <div className="faq-details">
            <div className="faq__top-wrapper">
              <p className="faq__section-title">{t.passport}</p>
              <span className="faq__line"></span>
              <div className="faq__wrapper">
                <p className="faq__topsubheader">{t.countryCode}</p>
                <p className="faq__country-code">
                  {data?.Passport[0]?.citizenship_data?.AlphaCode3}
                </p>
              </div>
              <span className="faq__line"></span>
              <div className="faq__wrapper">
                <p className="faq__topsubheader">{t.passportNumber}</p>
                <p className="faq__country-code">
                  {data?.Passport[0]?.PassportNumber}
                </p>
              </div>
            </div>
            <div className="faq__main-wrapper">
              <div className="d-flex flex-column align-items-center">
                <img
                  className="faq-photo"
                  src={
                    data?.Passport[0]?.Employee_data?.photo
                      ? data?.Passport[0].Employee_data?.photo
                      : accountImg
                  }
                  alt={t.passportPhotoAlt}
                />

                {dataFilter === "latest" ? (
                  <div className="faq__functions-wrapper d-flex">
                    <button
                      className="btn btn-secondary faq__edit-btn p-2 pe-3 ps-3"
                      onClick={handleEditClick}
                    >
                      <i className="fa fa-pencil"></i>
                    </button>

                    <button
                      className=" btn btn-secondary faq__pdf-btn p-2 pe-3 ps-3 me-1 ms-1"
                      onClick={handlePdfClick}
                    >
                      PDF
                    </button>

                    <button
                      className="btn btn-secondary faq__edit-btn p-2 pe-3 ps-3"
                      onClick={handleAddClick}
                    >
                      <i className="flaticon-067-plus"></i>
                    </button>
                  </div>
                ) : (
                  <div className="faq__functions-wrapper">
                    <button
                      className="btn btn-secondary faq__edit-btn p-2 pe-3 ps-3"
                      onClick={handleEditClick}
                    >
                      <i className="fa fa-pencil"></i> {t.edit}
                    </button>

                    <button
                      className=" btn btn-secondary faq__pdf-btn p-2 pe-3 ps-3 me-1 ms-1"
                      onClick={handlePdfClick}
                    >
                      PDF
                    </button>
                  </div>
                )}
              </div>
              <div className="faq__info">
                <div className="faq-field">
                  <span className="field-label">{t.surname}:</span>{" "}
                  <p className="field__data">
                    {data?.Passport[0].Employee_data?.surname}
                  </p>
                </div>
                <div className="faq-field">
                  <span className="field-label">{t.givenNames}:</span>{" "}
                  <p className="field__data">
                    {data?.Passport[0].Employee_data?.firstname}
                  </p>
                </div>
                <div className="faq-field">
                  <span className="field-label">{t.nationality}:</span>{" "}
                  <p className="field__data">
                    {
                      data?.Passport[0]?.citizenship_data?.[
                        "Country" + language.toUpperCase()
                      ]
                    }
                  </p>
                </div>
                <div className="faq__birthwrapper">
                  <div className="faq-field">
                    <span className="field-label">{t.birthDate}:</span>{" "}
                    <p className="field__data">
                      {data?.Passport[0]?.Employee_data?.date_of_birth}
                    </p>
                  </div>
                  <div className="faq-field">
                    <span className="field-label">{t.gender}:</span>{" "}
                    <p className="field__data">
                      {data?.Passport[0]?.Employee_data?.gender == "male"
                        ? t.male
                        : t.female}
                    </p>
                  </div>
                </div>
                <div className="faq__birthwrapper">
                  <div className="faq-field">
                    <span className="field-label">{t.dateOfExpiry}:</span>{" "}
                    <p className="field__data">
                      {data?.Passport[0]?.ValidityDate}
                    </p>
                  </div>
                  <div className="faq-field">
                    <span className="field-label">{t.dateOfIssue}:</span>{" "}
                    <p className="field__data">
                      {data?.Passport[0]?.IssueDate}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-data">
            {dataFilter === "latest" ? (
              <div className="no-data-wrapper">
                <p className="no-data">{t.noData}</p>
                <div className="faq__add-btn-wrapper">
                  <Button className="faq__edit-btn" onClick={handleAddClick}>
                    {t.addpassport}
                  </Button>
                </div>
              </div>
            ) : (
              <p>{t.noData}</p>
            )}
          </div>
        )}
      </div>

      {isWindowOpen && (
        <div className="overlay show" onClick={handleCloseWindow}>
          <div
            className="sliding-window show"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-btn" onClick={handleCloseWindow}>
              &times;
            </span>
            {windowContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default PassportFAQ;