import React, { useState, useEffect } from "react";
import "../styles.css";
import { Form, Button } from "react-bootstrap";
import swal from "sweetalert";
import accountImg from "../../../../images/account.jpg";

const MigrFAQ = ({
  data,
  openSection,
  handleToggle,
  t,
  m,
  token,
  id,
  dataFilter,
  handleUpdate,
  employee,
}) => {
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [windowContent, setWindowContent] = useState("");
  const urlLink = process.env.REACT_APP_API_URL;

  const handleAddClick = () => {
    const addContent = (
      <div className="edit-form">
        <h3>{t.addmigrationinformation}</h3>
        <Form onSubmit={handleAddSubmit}>
          <Form.Group controlId="formFrom">
            <Form.Label className="input__label">{t.fromDate}:</Form.Label>
            <Form.Control
              type="date"
              name="From"
              className="add__input input_height_default"
              required
            />
          </Form.Group>

          <Form.Group controlId="formTo">
            <Form.Label className="input__label">{t.todate}:</Form.Label>
            <Form.Control
              type="date"
              name="To"
              className="add__input input_height_default"
              required
            />
          </Form.Group>

          <Form.Group controlId="formDocumentSerie">
            <Form.Label className="input__label">{t.documentSerie}:</Form.Label>
            <Form.Control
              type="number"
              name="DocumentSerie"
              className="add__input input_height_default"
              required
            />
          </Form.Group>

          <Form.Group controlId="formDocumentNo">
            <Form.Label className="input__label">{t.documentNumber}:</Form.Label>
            <Form.Control
              type="number"
              name="DocumentNo"
              className="add__input input_height_default"
              required
            />
          </Form.Group>

          <Form.Group controlId="formVisitPurpose">
            <Form.Label className="input__label">{t.visitpurpose}:</Form.Label>
            <Form.Control
              as="select"
              name="VisitPurpose"
              className="add__input input_height_default"
              required
            >
              <option value="Служебный/ Official">Служебный/ Official</option>
              <option value="Туризм/ Tourism">Туризм/ Tourism</option>
              <option value="Коммерческий/ Business">
                Коммерческий/ Business
              </option>
              <option value="Учеба/ Education">Учеба/ Education</option>
              <option value="Работа/ Employment">Работа/ Employment</option>
              <option value="Частный/ Private">Частный/ Private</option>
              <option value="Транзит/ Transit">Транзит/ Transit</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formBorderCheckpoint">
            <Form.Label className="input__label">{t.bordercheckpoint}:</Form.Label>
            <Form.Control
              type="text"
              name="BorderCheckpoint"
              className="add__input input_height_default"
              required
            />
          </Form.Group>
          <Form.Group controlId="formFileUpload">
            <Form.Label className="input__label">{t.upload} (PDF/Image):</Form.Label>
            <Form.Control
              type="file"
              name="File"
              accept=".pdf, image/*"
              className="edit__input input_file_height_default"
            />
          </Form.Group>

          <div className="add__btn-wrapper mt-2">
            <Button className="add__btn btn_height_default" type="submit">
              {t.add}
            </Button>
          </div>
        </Form>
      </div>
    );

    setWindowContent(addContent);
    setIsWindowOpen(true);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    const formElements = e.target.elements;
    const fileInput = formElements.File;

    // Create a FormData object for file and other data
    const formData = new FormData();
    formData.append("Doc_Type_En", parseInt(7, 10));
    formData.append("Employee", parseInt(id, 10));
    formData.append("From", formatDateForInput(formElements.From.value));
    formData.append("To", formatDateForInput(formElements.To.value));
    formData.append(
      "DocumentSerie",
      parseInt(formElements.DocumentSerie.value, 10)
    );
    formData.append("DocumentNo", parseInt(formElements.DocumentNo.value, 10));
    formData.append("VisitPurpose", formElements.VisitPurpose.value);
    formData.append("BorderCheckpoint", formElements.BorderCheckpoint.value);

    if (fileInput.files[0]) {
      formData.append("file", fileInput.files[0]);
    }

    console.log("Add Payload (FormData):", formData);

    try {
      const response = await fetch(`${urlLink}/employee/migr-card/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Authorization header
        },
        body: formData, // Send FormData object as the body
      });

      if (response.ok) {
        const result = await response.json();
        swal(t.success.charAt(0).toUpperCase() + t.success.slice(1), t.migrationinformationaddedsuccessfully, "success");
        handleCloseWindow();
        handleUpdate(); // Trigger update to refresh the list
      } else {
        const errorData = await response.json();
        console.error("Error response from server:", errorData);
        throw new Error(
          errorData.message || t.failedtoaddmigrationinformation
        );
      }
    } catch (error) {
      swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), error.message, "error");
    }
  };

  const handleEditClick = () => {
    const editContent = (
      <div className="edit-form">
        <h3>{t.editmigrationinformation}</h3>
        <Form onSubmit={handleEditSubmit}>
          <Form.Group controlId="formFrom">
            <Form.Label className="input__label">{t.fromDate}:</Form.Label>
            <Form.Control
              type="date"
              name="From"
              className="edit__input input_height_default"
              defaultValue={formatDateToShow(data?.MigrCard[0]?.From)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formTo">
            <Form.Label className="input__label">{t.todate}:</Form.Label>
            <Form.Control
              type="date"
              name="To"
              className="edit__input input_height_default"
              defaultValue={formatDateToShow(data?.MigrCard[0]?.To)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formDocumentSerie">
            <Form.Label className="input__label">{t.documentSerie}:</Form.Label>
            <Form.Control
              type="number"
              name="DocumentSerie"
              className="edit__input input_height_default"
              defaultValue={data?.MigrCard[0]?.DocumentSerie}
              required
            />
          </Form.Group>

          <Form.Group controlId="formDocumentNo">
            <Form.Label className="input__label">{t.documentNumber}:</Form.Label>
            <Form.Control
              type="number"
              name="DocumentNo"
              className="edit__input input_height_default"
              defaultValue={data?.MigrCard[0]?.DocumentNo}
              required
            />
          </Form.Group>

          <Form.Group controlId="formVisitPurpose">
            <Form.Label className="input__label">{t.visitpurpose}:</Form.Label>
            <Form.Control
              as="select"
              name="VisitPurpose"
              className="edit__input input_height_default"
              defaultValue={data?.MigrCard[0]?.VisitPurpose}
              required
            >
              <option value="Служебный/ Official">Служебный/ Official</option>
              <option value="Туризм/ Tourism">Туризм/ Tourism</option>
              <option value="Коммерческий/ Business">
                Коммерческий/ Business
              </option>
              <option value="Учеба/ Education">Учеба/ Education</option>
              <option value="Работа/ Employment">Работа/ Employment</option>
              <option value="Частный/ Private">Частный/ Private</option>
              <option value="Транзит/ Transit">Транзит/ Transit</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formBorderCheckpoint">
            <Form.Label className="input__label">{t.bordercheckpoint}:</Form.Label>
            <Form.Control
              type="text"
              name="BorderCheckpoint"
              className="edit__input input_height_default"
              defaultValue={data?.MigrCard[0]?.BorderCheckpoint}
              required
            />
          </Form.Group>

          <Form.Group controlId="formFileUpload">
            <Form.Label className="input__label">{t.upload} (PDF/Image):</Form.Label>
            <Form.Control
              type="file"
              name="File"
              accept=".pdf, image/*"
              className="edit__input input_file_height_default"
            />
          </Form.Group>

          <div className="edit__btn-wrapper mt-2">
            <Button className="edit__btn btn_height_default" type="submit">
              {t.save}
            </Button>
          </div>
        </Form>
      </div>
    );

    setWindowContent(editContent);
    setIsWindowOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const formElements = e.target.elements;
    const fileInput = formElements.File;
    const employeeId = parseInt(id, 10);

    formData.append("From", formatDateForInput(formElements.From.value));
    formData.append("To", formatDateForInput(formElements.To.value));
    formData.append("DocumentSerie", formElements.DocumentSerie.value);
    formData.append("DocumentNo", formElements.DocumentNo.value);
    formData.append("VisitPurpose", formElements.VisitPurpose.value);
    formData.append("BorderCheckpoint", formElements.BorderCheckpoint.value);
    formData.append("Employee", employeeId);
    if (fileInput.files[0]) {
      formData.append("file", fileInput.files[0]);
    }

    try {
      const response = await fetch(
        `${urlLink}/employee/migr-card/${data.MigrCard[0].id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        swal(
          t.success.charAt(0).toUpperCase() + t.success.slice(1),
          t.migrationinformationupdatedsuccessfully,
          "success"
        );
        handleCloseWindow();
        handleUpdate();
      } else {
        // Log raw response text
        const errorText = await response.text();
        console.error("Raw error response from server:", errorText);

        // Attempt to parse the error response as JSON
        let errorData;
        try {
          errorData = JSON.parse(errorText);
        } catch (parseError) {
          errorData = { message: "Unable to parse error response" };
        }

        // Display detailed error message
        const errorMessage =
          errorData?.message || t.failedtoupdatemigrationinformation;

        // Display detailed error message to the user
        swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), `${t.error.charAt(0).toUpperCase() + t.error.slice(1)}: ${errorMessage}`, "error");
      }
    } catch (error) {
      console.error("Error in the request:", error);
      swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), error.message, "error");
    }
  };

  const formatDateForInput = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  const formatDateToShow = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split(".");
    return `${year}-${month}-${day}`;
  };

  const handleCloseWindow = () => {
    setIsWindowOpen(false);
  };

  const handlePdfClick = () => {
    const file = data.MigrCard[0]?.file;

    const cleanFileUrl = file?.split("?")[0];

    let content;
    if (cleanFileUrl?.endsWith(".pdf")) {
      content = (
        <iframe
          src={file}
          width="100%"
          height="100%"
          title="PDF Viewer"
          frameBorder="0"
          allowFullScreen
        />
      );
    } else {
      content = (
        <img
          className="sliding-image"
          src={file || accountImg}
          alt="PDF or Image"
        />
      );
    }

    setWindowContent(content);
    setIsWindowOpen(true);
  };
  const [datacheck, setDatacheck] = useState(true)
  useEffect(() => {
    if(!data?.MigrCard[0]){
      setDatacheck(false)
    }
    for(let i in data?.MigrCard[0]){
      if(typeof data?.MigrCard[0]?.[i] === "string" && data?.MigrCard[0]?.[i] == ""){
        setDatacheck(false)
      }
      else if(data?.MigrCard[0]?.[i] === null&& i != "file") setDatacheck(false)
      else if(data?.MigrCard[0]?.[i] instanceof  Object && Object.keys(data?.MigrCard[0]?.[i]).length == 0){
        setDatacheck(false)
      }
      else setDatacheck(true)
    }
  }, [data?.MigrCard[0]])
  return (
    <div style={!datacheck ? {background: "rgb(223,223,223)"} : null} className="faq-container6">
      <div onClick={() => handleToggle("migrInfo")} className="faq__t-wrapper">
        <h5 className="toggle-title">{t.migrationInformation}</h5>
        <p className="faq__header-seria">
          {data?.MigrCard && data?.MigrCard.length > 0
            ? data?.MigrCard[0].From
            : t.noMigrationInformation}
        </p>
      </div>
      <div
        className={`collapsible-content ${
          openSection === "migrInfo" ? "open" : ""
        }`}
      >
        {data?.MigrCard && data?.MigrCard.length > 0 ? (
          <div className="faq-details">
            <div className="faq__top-wrapper">
              <p className="faq__section-title">{t.migrationCard}</p>
              <span className="faq__line"></span>
              <div className="faq__wrapper">
                <p className="faq__topsubheader">{t.issuedate}</p>
                <p className="faq__country-code">{data?.MigrCard[0].From}</p>
              </div>
            </div>
            <div className="faq__main-wrapper">
              <div className="faq__info">
                <div className="faq-field">
                  <span className="field-label">{m.fullname}:</span>
                  <p className="field__data">
                    {employee.firstname} {employee.surname}
                  </p>
                </div>
                <div className="faq-field">
                  <span className="field-label">{t.bordercheckpoint}:</span>
                  <p className="field__data">
                    {data?.MigrCard[0].BorderCheckpoint}
                  </p>
                </div>
                <div className="faq-field">
                  <span className="field-label">{t.visitpurpose}:</span>
                  <p className="field__data">
                    {data?.MigrCard[0].VisitPurpose}
                  </p>
                </div>

                <button
                  className="btn btn-secondary mt-2 me-2 faq__edit-btn"
                  onClick={handleEditClick}
                >
                  <i className="fa fa-pencil"></i> {t.edit}
                </button>

                <button
                  className=" btn btn-secondary mt-2 faq__pdf-btn"
                  onClick={handlePdfClick}
                >
                  PDF
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-data">
            {dataFilter === "latest" ? (
              <div className="no-data-wrapper">
                <p className="no-data">{t.noData}</p>
                <div className="faq__add-btn-wrapper">
                  <Button className="faq__edit-btn" onClick={handleAddClick}>
                    {t.addmigrationinformation}
                  </Button>
                </div>
              </div>
            ) : (
              <p>{t.noData}</p>
            )}
          </div>
        )}
      </div>

      {isWindowOpen && (
        <div className="overlay show" onClick={handleCloseWindow}>
          <div
            className="sliding-window show"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-btn" onClick={handleCloseWindow}>
              &times;
            </span>
            {windowContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default MigrFAQ;