import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import swal from "sweetalert";
import { v4 as uuidv4 } from 'uuid'; //key={uuidv4()}
import { BsChevronDoubleDown } from "react-icons/bs";
import { Badge, Modal } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
//Images
import { Button, Form } from "react-bootstrap";
import pattern6 from "./../../../images/pattern/pattern6.png";
import circle from "./../../../images/pattern/circle.png";
//Components
import { ThemeContext } from "../../../context/ThemeContext";
import DropdownBlog from "../Dompet/DropdownBlog";
import PreviousTab from "../Dompet/Home/PreviousTab";
import InvoiceCard from "../Dompet/Home/InvoiceCard";
import SpendingsBlog from "../Dompet/Home/SpendingsBlog";
import QuickTransferBlog from "../Dompet/Home/QuickTransferBlog";
import CardBlog from "../Dompet/Home/CardBlog";
import ChartPie from "../charts/Chartjs/pie";
import { useLanguage } from "../../../context/LanguageContext";
import translations from "../../../translation/translation";
import { capitalizeFirstLetter, numberSeperate } from '../../../utils/Numberformat'
const PolarChart = loadable(() =>
  pMinDelay(import("../Dompet/Home/PolarChart"), 1000)
);
const ActivityApexBarGraph = loadable(() =>
  pMinDelay(import("../Dompet/Home/ActivityApexBarGraph"), 1000)
);
const TransactionApexBar = loadable(() =>
  pMinDelay(import("../Dompet/Home/TransactionApexBar"), 1000)
);
const Home = () => {
  const history = useNavigate()
  const [dashboardData, setDashboardData] = useState({ total_salary: 0, check_attach: [], check_data: { employees: [] }, salary_status: {paid: [], unpaid: []} });
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.access;
  const [content, setContent] = useState([]);
  const [project, setProject] = useState([]);
  const [employee, setEmployee] = useState([]);
  const { language } = useLanguage();
  const t = translations[language];
  const [isPaidEmployee, setIsPaidEmployee] = useState('')
  const [employeePaymentData, setEmployeePaymentData] = useState([])
  const [showModalPayment, setShowModalPayment] = useState(false)
  useEffect(() => {
    setEmployeePaymentData(dashboardData.salary_status[isPaidEmployee.toLowerCase()]);
  }, [isPaidEmployee])
  const [filterIsFilledData, setFilterIsFilledData] = useState({
    has_daktil_file: "",
    has_inn_file: "",
    has_med_file: "",
    has_migrcard_file: "",
    has_passport_file: "",
    has_patent_file: "",
    has_rusLcert_file: "",
    has_snils_file: "",
    has_visa_file: "",
    has_vnj_file: "",
    citizenship__AlphaCode3: ""
  })
  const [showModalCitizenship, setShowModalCitizenship] = useState(false);
  const [showModalUnregister, setShowModalUnregister] = useState(false);
  const urlLink = process.env.REACT_APP_API_URL;
  const isFilledFilter = e => {
    const { name, value } = e.target
    setFilterIsFilledData({ ...filterIsFilledData, [name]: value });
  }
  const [dataUsers, setDataUsers] = useState([])
  const [clearFilterBtnState, setClearFilterBtnState] = useState(true)
  useEffect(() => {
    if (Array.isArray(dashboardData.check_attach)) {
    if (Object.values(filterIsFilledData).every(a => a == "")) {
      setDataUsers(dashboardData.check_attach)
      setClearFilterBtnState(true)
    }
    else {
      setClearFilterBtnState(false)
      const arr = []
      for (let i of dashboardData.check_attach) {
        let count = 0
        for (let n in filterIsFilledData) {
          if (n == "employee") {
            if (`${language == "ru"?i.surname:i.surname_latin ? i.surname_latin : i.surname} ${language == "ru"?i.firstname:i.firstname_latin ? i.firstname_latin : i.firstname}`.slice(0, filterIsFilledData[n]?.length).toLowerCase() === filterIsFilledData[n].trim().toLowerCase()) count++;
            else if (`${language == "ru"?i.firstname:i.firstname_latin ? i.firstname_latin : i.firstname} ${language == "ru"?i.surname:i.surname_latin ? i.surname_latin : i.surname}`.slice(0, filterIsFilledData[n]?.length).toLowerCase() === filterIsFilledData[n].trim().toLowerCase()) count++;
          }
          if(n == "citizenship__AlphaCode3"){
            if(i[n] == filterIsFilledData[n]) count++
            else if(JSON.stringify(i[n]) == filterIsFilledData[n]) count++
          }
          else { if (i[n]?.toString() === filterIsFilledData[n]) count++; }
        }
        if (count == Object.values(filterIsFilledData).filter(d => d != "")?.length) arr.push(i);
      }
      setDataUsers(arr)
    }}
  }, [filterIsFilledData])
  const [countries, setCountries] = useState([])
useEffect(() => {
  if(showModalUnregister){
    setUnregisteredColumns(dashboardData.check_data.employees?.length)
  }
  else{
    setUnregisteredColumns(5)
  }
}, [showModalUnregister])
useEffect(() => {
  if(showModalCitizenship){
    setCitizenshipColumns(content?.length)
  }
  else{
    setCitizenshipColumns(5)
  }
}, [showModalCitizenship])
const [reRenderState, setReRenderState] = useState(false)
  useEffect(() => {
    const url = `${urlLink}/dashboard-info/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${t.httperrorstatus}${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if(data){ 
        const {check_attach} = data
        setDataUsers(check_attach|| []); // Ensure it's always an array
        setEmployee(data.number_of_employees);
        setProject(data.number_of_projects);
        setContent(data.grouped_data);
        setDashboardData(data);
        for(let i = 0; i < data.check_attach?.length - 1; i++){
          if(!countries.includes(data.check_attach[i].citizenship__AlphaCode3)){
            countries.push(data.check_attach[i].citizenship__AlphaCode3)}
            setCountries(countries)
        }
      }
      else setReRenderState(!reRenderState)
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        setReRenderState(!reRenderState)
        });
      }, [token, reRenderState]);
  const [citizenshipColumns, setCitizenshipColumns] = useState(5) 
  const [unregisteredColumns, setUnregisteredColumns] = useState(5)
  const renderTableRows = () => {
    return content?.slice(0,citizenshipColumns).map((content) => (
      <tr key={content?.id || uuidv4()}>
        <td>{content?.[`citizenship__Country${language.toUpperCase()}`] || "Country"}</td>
        <td>{content?.employee_count || 0}</td>
      </tr>
    ));
  };
  const renderTableUnregisteredUsers = () => {
    return employeePaymentData?.map((content) => (
      <tr key={uuidv4()}>
        <td>{language == "ru"?content?.surname:content?.surname_latin ? capitalizeFirstLetter(content?.surname_latin) : capitalizeFirstLetter(content?.surname)}</td>
        <td>{language == "ru"?content?.firstname:content?.firstname_latin ? capitalizeFirstLetter(content?.firstname_latin) : capitalizeFirstLetter(content?.firstname)}</td>
      </tr>
    ));
  };
  const renderTableIsFilledData = () => {
    return dataUsers.map((content) => (
      <tr className="check_attach__table-tr" key={uuidv4()}>
        <td className="table-dashboard-td-name">{`${language == "ru"?content?.surname:content?.surname_latin ? content?.surname_latin : content?.surname} ${language == "ru"?content?.firstname:content?.firstname_latin ? content?.firstname_latin : content?.firstname}`}</td>
        <td className="table-dashboard-td-name">{content?.citizenship__AlphaCode3 || "N/A"}</td>
        <td><span style={{background: content?.has_daktil_file ? "rgba(0, 185, 0)" : "red"}}>{content?.has_daktil_file ? "Yes" : "No"}</span></td>
        <td><span style={{background: content?.has_inn_file ? "rgba(0, 185, 0)" : "red"}}>{content?.has_inn_file ? "Yes" : "No"}</span></td>
        <td><span style={{background: content?.has_med_file ? "rgba(0, 185, 0)" : "red"}}>{content?.has_med_file ? "Yes" : "No"}</span></td>
        <td><span style={{background: content?.has_migrcard_file ? "rgba(0, 185, 0)" : "red"}}>{content?.has_migrcard_file ? "Yes" : "No"}</span></td>
        <td><span style={{background: content?.has_passport_file ? "rgba(0, 185, 0)" : "red"}}>{content?.has_passport_file ? "Yes" : "No"}</span></td>
        <td><span style={{background: content?.has_patent_file ? "rgba(0, 185, 0)" : "red"}}>{content?.has_patent_file ? "Yes" : "No"}</span></td>
        <td><span style={{background: content?.has_rusLcert_file ? "rgba(0, 185, 0)" : "red"}}>{content?.has_rusLcert_file ? "Yes" : "No"}</span></td>
        <td><span style={{background: content?.has_snils_file ? "rgba(0, 185, 0)" : "red"}}>{content?.has_snils_file ? "Yes" : "No"}</span></td>
        <td><span style={{background: content?.has_visa_file ? "rgba(0, 185, 0)" : "red"}}>{content?.has_visa_file ? "Yes" : "No"}</span></td>
        <td><span style={{background: content?.has_vnj_file ? "rgba(0, 185, 0)" : "red"}}>{content?.has_vnj_file ? "Yes" : "No"}</span></td>
      </tr>
    ));
  };
  return (
    <>
      <div className="row invoice-card-row">
        <InvoiceCard employee={employee} project={project} />
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-xl-6">
                  <div className="card-bx bg-blue">
                    <img className="pattern-img" src={pattern6} alt="" />
                    <div className="card-info text-white">
                      <img src={circle} className="mb-4" alt="" />
                      <h2 className="text-white card-balance">₽{numberSeperate(dashboardData.total_salary) || 0}</h2>
                      <p className="fs-16">{t.totalpayroll}</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="row mt-xl-0 mt-4">
                    <div className="col-md-6">
                      <h4 className="card-title">{t.payrolloverview}</h4>
                      <span>
                        {t.diagrammofpaidandunpaidemployee}
                      </span>
                      <ul className="card-list mt-4">
                        <li>
                          <span className="bg-success circle"></span>{t.paid}
                          <span>{numberSeperate(dashboardData?.salary_status?.paid_count && dashboardData?.salary_status?.unpaid_count ? (dashboardData?.salary_status?.paid_count/(dashboardData?.salary_status?.paid_count + dashboardData?.salary_status?.unpaid_count))*100 : 50)}%</span>
                        </li>
                        <li>
                          <span className="bg-danger circle"></span>{t.unpaid}
                          <span>{numberSeperate(dashboardData?.salary_status?.paid_count && dashboardData?.salary_status?.unpaid_count ? (dashboardData?.salary_status?.unpaid_count/(dashboardData?.salary_status?.paid_count + dashboardData?.salary_status?.unpaid_count))*100 : 50)}%</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ChartPie setShowModalPayment={setShowModalPayment} setIsPaidEmployee={setIsPaidEmployee}
                        data={[dashboardData?.salary_status?.paid_count && dashboardData?.salary_status?.unpaid_count ? (dashboardData?.salary_status?.paid_count/(dashboardData?.salary_status?.paid_count + dashboardData?.salary_status?.unpaid_count))*100 : 50, dashboardData?.salary_status?.paid_count && dashboardData?.salary_status?.unpaid_count ? (dashboardData?.salary_status?.unpaid_count/(dashboardData?.salary_status?.paid_count + dashboardData?.salary_status?.unpaid_count))*100 : 50]}
                        labels={["Paid", "Unpaid"]}
                        colors={["#68e365", "#f44336"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModalCitizenship} onHide={() => setShowModalCitizenship(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t.citizenship}</Modal.Title>
          </Modal.Header>
        <Modal.Body>
        <div style={{height: "580px"}} className="table-responsive">
                <table className="table card-table transactions-table citizenship-dashboard-table">
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }}>{t.citizenship}</th>
                      <th style={{ width: "50%" }}>{t.numberofEmployee}</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableRows()}</tbody>
                </table>
              </div>
              <div className="w-100 d-flex justify-content-end mt-2">
      <Button className="btn_height_default" variant="secondary" onClick={() => setShowModalCitizenship(false)}>
            {t.close}
          </Button>
          </div>
        </Modal.Body>
        </Modal>
        <Modal show={showModalUnregister} onHide={() => setShowModalUnregister(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t.unregisteredemployees}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <div style={{height: "580px"}} className="table-responsive">
                <table className="table citizenship-dashboard-table card-table transactions-table">
                  <thead>
                    <tr>
                      <th>{capitalizeFirstLetter(t.first_name)}</th>
                      <th>{capitalizeFirstLetter(t.surname)}</th>
                      <th>{t.status}</th>
                      <th>{t.remaining_days}</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableUnregisteredUsers()}</tbody>
                </table>
              </div>
              <div className="w-100 d-flex justify-content-end mt-2">
      <Button className="btn_height_default" variant="secondary" onClick={() => setShowModalUnregister(false)}>
            {t.close}
          </Button>
          </div>
          </Modal.Body>
        </Modal>
        <Modal show={showModalPayment} onHide={() => setShowModalPayment(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t[`${isPaidEmployee.toLowerCase()}employee`]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <div style={{height: "580px"}} className="table-responsive">
                <table className="table citizenship-dashboard-table card-table transactions-table">
                  <thead>
                    <tr>
                      <th>{capitalizeFirstLetter(t.first_name)}</th>
                      <th>{capitalizeFirstLetter(t.surname)}</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableUnregisteredUsers()}</tbody>
                </table>
              </div>
              <div className="w-100 d-flex justify-content-end mt-2">
      <Button className="btn_height_default" variant="secondary" onClick={() => setShowModalPayment(false)}>
            {t.close}
          </Button>
          </div>
          </Modal.Body>
        </Modal>
        <div className="dashboard-citizenship-unregistered-tables__wrapper">
<div>
        <div className="citizenship-dashboard-table__wrapper">
          <div className="card">
            <div
              className="card-header"
              style={{ padding: "20px", paddingLeft: "30px" }}
            >
              <h4 className="card-title">{t.citizenship}</h4>
            </div>
            <div className="card-body" style={{ padding: "20px" }}>
              <div className="table-responsive">
                <table className="table card-table transactions-table citizenship-dashboard-table">
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }}>{t.citizenship}</th>
                      <th style={{ width: "50%" }}>{t.numberofEmployee}</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableRows()}</tbody>
                </table>
              </div>
                {content?.length > 5 ? <div className="dashboard-unregistered-table-btn-down">
                  <i style={citizenshipColumns != 5 ? {transform: "rotate(180deg)"} : null } onClick={() => {
                    if(content?.length > 10) {
                      setShowModalCitizenship(true)
                    }
                    else if(citizenshipColumns === 5)setCitizenshipColumns(content?.length)
                    else setCitizenshipColumns(5)
                  }}><BsChevronDoubleDown/></i>
                </div> : null}
            </div>
          </div>
        </div>
        </div>
        <div>
        <div className="citizenship-dashboard-table__wrapper">
          <div className="card">
            <div
              className="card-header"
              style={{ padding: "20px", paddingLeft: "30px" }}
            >
              <h4 className="card-title">{t.unregisteredemployees}</h4>
            </div>
            <div className="card-body" style={{ padding: "20px" }}>
              <div className="table-responsive">
                <table className="table citizenship-dashboard-table card-table transactions-table">
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }}>{capitalizeFirstLetter(t.first_name)}</th>
                      <th style={{ width: "50%" }}>{capitalizeFirstLetter(t.surname)}</th>
                      <th>{t.status}</th>
                      <th>{t.remaining_days}</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableUnregisteredUsers()}</tbody>
                </table>
              </div>
                {dashboardData.check_data.employees?.length > 5 ? <div className="dashboard-unregistered-table-btn-down">
                  <i style={unregisteredColumns != 5 ? {transform: "rotate(180deg)"} : null } onClick={() => {
                    if(dashboardData.check_data.employees?.length > 10){
                      setShowModalUnregister(true)
                    }
                    else if(unregisteredColumns === 5)setUnregisteredColumns(dashboardData.check_data.employees?.length)
                    else setUnregisteredColumns(5)
                  }}><BsChevronDoubleDown/></i>
                </div> : null}
            </div>
          </div>
        </div>
        </div>
        </div>
        <div style={{height: "650px"}}>
        <div>
          <div className="card">
            <div
              className="card-header"
              style={{ padding: "20px", paddingLeft: "30px" }}
            >
              <h4 className="card-title">{t.employeesdocuments}</h4>
              <Button onClick={() => {
                  setFilterIsFilledData({})
              }} disabled={clearFilterBtnState} className="edit__btn">
              {t.clearfilter}
            </Button>
            </div>
            <div className="card-body" style={{ padding: "20px" }}>
              <div className="table-responsive filled-unfilled-table">
                <table style={{height: `${(dataUsers?.length > 5 ? 500 : dataUsers?.length *100) }px`}} className="table table-responsive-md card-table transactions-table">
                  <thead>
                    <tr>
                      <th>{t.employee}</th>
                      <th>{t.citizenship}</th>
                      <th>{t.daktilfile}</th>
                      <th>{t.innfile}</th>
                      <th>{t.medfile}</th>
                      <th>{t.migrcardfile}</th>
                      <th>{t.passportfile}</th>
                      <th>{t.patentfile}</th>
                      <th>rusLcert file</th>
                      <th>{t.snilsfile}</th>
                      <th>{t.visafile}</th>
                      <th>vnj file</th>
                    </tr>
                    <tr style={{ position: "sticky", top: "0", background: "#fff" }}>
                      <th><Form.Control value={filterIsFilledData.employee ? filterIsFilledData.employee : "" } name="employee" onInput={isFilledFilter} className="input_height_default" type="text" placeholder={t.employee}/></th>
                      <th><Form.Control value={filterIsFilledData.citizenship__AlphaCode3 ? filterIsFilledData.citizenship__AlphaCode3 : "" } onChange={isFilledFilter} name="citizenship__AlphaCode3" className="input_height_default" as="select">
                      <option value="">{t.citizenship}</option>
                        {countries.map((e) => 
                        <option key={uuidv4()} value={e || "null"}>{e || "N/A"}</option>
                        )}
                        </Form.Control></th>
                      <th><Form.Control value={filterIsFilledData.has_daktil_file ? filterIsFilledData.has_daktil_file : "" } onChange={isFilledFilter} name="has_daktil_file" className="input_height_default" as="select">
                        <option value="">{t.daktilfile}</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                        </Form.Control></th>
                      <th><Form.Control value={filterIsFilledData.has_inn_file ? filterIsFilledData.has_inn_file : "" } onChange={isFilledFilter} name="has_inn_file" className="input_height_default" as="select">
                        <option value="">{t.innfile}</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                        </Form.Control></th>
                      <th><Form.Control value={filterIsFilledData.has_med_file ? filterIsFilledData.has_med_file : "" } onChange={isFilledFilter} name="has_med_file" className="input_height_default" as="select">
                        <option value="">{t.medfile}</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                        </Form.Control></th>
                      <th><Form.Control value={filterIsFilledData.has_migrcard_file ? filterIsFilledData.has_migrcard_file : "" } onChange={isFilledFilter} name="has_migrcard_file" className="input_height_default" as="select">
                        <option value="">{t.migrcardfile}</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                        </Form.Control></th>
                      <th><Form.Control value={filterIsFilledData.has_passport_file ? filterIsFilledData.has_passport_file : "" } onChange={isFilledFilter} name="has_passport_file" className="input_height_default" as="select">
                        <option value="">{t.passportfile}</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                        </Form.Control></th>
                      <th><Form.Control value={filterIsFilledData.has_patent_file ? filterIsFilledData.has_patent_file : "" } onChange={isFilledFilter} name="has_patent_file" className="input_height_default" as="select">
                        <option value="">{t.patentfile}</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                        </Form.Control></th>
                      <th><Form.Control value={filterIsFilledData.has_rusLcert_file ? filterIsFilledData.has_rusLcert_file : "" } onChange={isFilledFilter} name="has_rusLcert_file" className="input_height_default" as="select">
                        <option value="">rusLcert file</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                        </Form.Control></th>
                      <th><Form.Control value={filterIsFilledData.has_snils_file ? filterIsFilledData.has_snils_file : "" } onChange={isFilledFilter} name="has_snils_file" className="input_height_default" as="select">
                        <option value="">{t.snilsfile}</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                        </Form.Control></th>
                      <th><Form.Control value={filterIsFilledData.has_visa_file ? filterIsFilledData.has_visa_file : "" } onChange={isFilledFilter} name="has_visa_file" className="input_height_default" as="select">
                        <option value="">{t.visafile}</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                        </Form.Control></th>
                      <th><Form.Control value={filterIsFilledData.has_vnj_file ? filterIsFilledData.has_vnj_file : "" } onChange={isFilledFilter} name="has_vnj_file" className="input_height_default" as="select">
                        <option value="">vnj file</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                        </Form.Control></th>
                    </tr>
                  </thead>
                  <tbody>{renderTableIsFilledData()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Home;