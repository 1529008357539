import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Spinner, InputGroup, Modal } from "react-bootstrap";
import { fetchCitizenship, fetchDoc } from "../../components/apiData/apiData";
import swal from "sweetalert";
import "./employee.css";
import { useNavigate } from "react-router-dom";
import { getDecodedRefreshTokenFromLocalStorage } from "../../../jwt/jwtDecoder";
import { useLanguage } from "../../../context/LanguageContext";
import translations from "../../../translation/translation";
import { convertToCyrillic } from "../../../utils/latinToCyrillicUtils";
import { RiRobot2Line } from "react-icons/ri";
import { FaCloudArrowUp } from 'react-icons/fa6'
import { IoCloudDoneSharp } from "react-icons/io5";
import {  FaCloud  } from "react-icons/fa";
import { TbRobotOff } from "react-icons/tb";
const AddEmployee = () => {
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.access;
  const [citizenship, setCitizenship] = useState([]);
  const history = useNavigate();
  const decodedToken = getDecodedRefreshTokenFromLocalStorage("userDetails");
  const userID = decodedToken.payload.user_id;
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useLanguage();
  const t = translations[language];
  const dateInputRef = useRef(null);
  const issueDateRef = useRef(null);
  const validityDateRef = useRef(null);
  const [selectorData, setSelectorData] = useState({})
  const [isFullWindowOpen, setIsFullWindowOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    passportNumber: "",
    name: "",
    firstname_latin: "",
    surname_latin: "",
    surname: "",
    date_of_birth: "",
    IssueDate: "",
    ValidityDate: "",
    IssueBy: "",
    CountryPersNo: "",
    gender: "",
    citizenship_data: "",
    personnel_number: "",
    other_name: "",
    other_name_latin: "",
    file: null
  });
  const countiesFetch = async () => {
    const response = await fetch(`${urlLink}/gendt-country/`)
    const {Response} = await response.json()
    const foundData = Response.find(a => a.AlphaCode3 == selectorData["country"])
    employeeData["citizenship_data"] = (foundData)?.id
    setSearchCountryInputPost((foundData)?.["Country"+language.toUpperCase()]|| "")
  }
  const [checkState, setCheckState] = useState(false)
  useEffect(() => {
        if(selectorData && Object.keys(selectorData)?.length > 0){
          countiesFetch()
        employeeData["surname"]=convertToCyrillic(selectorData["surname"]) || ""
        employeeData["surname_latin"]=(selectorData["surname"]) || ""
        employeeData["firstname_latin"]=selectorData["names"] || ""
        employeeData["firstname"]=convertToCyrillic(selectorData["names"]) || ""
        if(selectorData["date_of_birth"]){
        const parts = selectorData["date_of_birth"]?.split('.');
        employeeData["date_of_birth"]= `${parts[2]}-${parts[1]}-${parts[0]}` || ""
      }
      if(selectorData["expiration_date"]){
        const parts2 = selectorData["expiration_date"]?.split('.')
        employeeData["ValidityDate"]=`${parts2[2]}-${parts2[1]}-${parts2[0]}`|| ""}
        employeeData["IssueDate"]=selectorData["IssueDate"] || ""
        employeeData["gender"]=(selectorData["sex"] == "M" ? "male" : "female") || ""
        employeeData["personnel_number"]=selectorData["personal_number"]|| ""
        employeeData["passportNumber"]=selectorData["number"]|| ""
        employeeData["CountryPersNo"]=selectorData["personal_number"]|| ""
        setCheckState(true)
       } 
  }, [selectorData])
  const [selectedFile,setSelectedFile] = useState('')
  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState({})
  const deleteAutoFill = () => {
    employeeData["surname"]=""
    employeeData["surname_latin"]=""
    employeeData["firstname_latin"]=""
    employeeData["firstname"]=""
    employeeData["date_of_birth"]=""
    employeeData["ValidityDate"]=""
    employeeData["IssueDate"]=""
    employeeData["gender"]=""
    employeeData["personnel_number"]=""
    employeeData["passportNumber"]=""
    employeeData["CountryPersNo"]=""
    setSelectorData({})
    setSearchCountryInputPost('')
    setCheckState(false)
    setSelectedImage({})
    for(let i in selectorData){
      delete selectorData[i]
    }
  }
  const handleBack = () => {
    deleteAutoFill()
    history(-1);
  };
  const [fullwindowSelectedData, setFullwindowSelectedData] = useState()
  const urlLink = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const getCitizenship = async () => {
      const data = await fetchCitizenship(token);
      setCitizenship(data);
    };

    getCitizenship();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Restrict year length to 4 characters in date inputs
    if (name.includes("date")) {
      const [year] = value?.split("-");
      if (year.length > 4) return;
    }

    setEmployeeData({
      ...employeeData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    // Construct the JSON payload
    let jsonPayload = {
      date_of_birth: formatDateForInput(employeeData.date_of_birth),
      ValidityDate: formatDateForInput(employeeData.ValidityDate),
      IssueDate: formatDateForInput(employeeData.IssueDate),
      responsible_user: Number(userID),
      citizenship: citizenship.find(a => a["Country" + language.toUpperCase()].toLowerCase() == employeeData.citizenship_data)?.id || employeeData.citizenship_data,
      personnel_number: employeeData.personnel_number,
      surname: employeeData.surname,
      IssuedBy: employeeData.IssueBy,
      CountryPersNo: employeeData.CountryPersNo,
      firstname: employeeData.firstname,
      firstname_latin: employeeData.firstname_latin,
      surname_latin: employeeData.surname_latin,
      PassportNumber: employeeData.passportNumber,
      gender: employeeData.gender,
      other_name: employeeData.other_name,
      other_name_latin: employeeData.other_name_latin
    };
  
    // FormData for file
    const formData = new FormData();
    formData.append("file", Object.keys(selectorData)?.length > 0 ? selectedPhoto : selectedFile);
  
    // Add other fields from jsonPayload
    for (let key in jsonPayload) {
      formData.append(key, jsonPayload[key]);
    }
  
    try {
      const today = new Date();
      const birthDate = new Date(employeeData.date_of_birth);
  
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();
      const currentDay = today.getDate();
  
      const birthYear = birthDate.getFullYear();
      const birthMonth = birthDate.getMonth();
      const birthDay = birthDate.getDate();
  
      let age = currentYear - birthYear;
  
      if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
        age--;
      }
  
      if (age < 18) {
        throw new Error(t.agemustbeequalorgreaterthan18); // Age check
      }
  
      // Send POST request with formData
      const response = await fetch(`${urlLink}/employee-passport/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData // Send formData, which includes both the file and other fields
      });
  
      const data = await response.json(); // Read the response only once
  
      if (data.Response) {
        swal(
          t.success.charAt(0).toUpperCase() + t.success.slice(1),
          data.message || t.employeeaddedsuccessfully,
          "success"
        ).then(() => {
          handleBack();
        });
        setErrors({});
      } else throw new Error(data.detail)
    } catch (error) {
      swal(
        t.error.charAt(0).toUpperCase() + t.error.slice(1),
        t.failedtoaddtreasure + (error.message || t.anerrorocurred),
        "error"
      );
      setErrors({});
    }
  };
    

  const formatDateForInput = (dateString) => {
    const [year, month, day] = dateString?.split("-");
    return `${day}.${month}.${year}`;
  };
  const [searchCountryInputPost, setSearchCountryInputPost] = useState('')
  const [countriesDataPost, setCountriesDataPost] = useState(citizenship || [])
  const [countrySearchBoxStatePost, setCountrySearchBoxStatePost] = useState(false)
  const [countryStyleIndexPost, setCountryStyleIndexPost] = useState(null)
  useEffect(() => {
    setCountriesDataPost(citizenship.filter(e=>e["Country"+language.toUpperCase()].slice(0, searchCountryInputPost.length).toLowerCase() == searchCountryInputPost.trim().toLowerCase()))
    employeeData.citizenship_data = searchCountryInputPost.trim('').toLowerCase()
    setCountryStyleIndexPost()
  }, [searchCountryInputPost])
  useEffect(() => {
    setCountriesDataPost(citizenship)
  }, [countrySearchBoxStatePost])
  const autofillSubmit = async () => {
    try {
      setAutofillLoading(true)
      const formData = new FormData();
    formData.append("file", selectedPhoto)
    const response = await fetch(`${urlLink}/passport-reader/`,{
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData
    })
    const data = await response.json()
    if(data?.mrz_data){
      setSelectorData(data?.mrz_data)
      setSelectedImage(selectedPhoto)
      setAutofillLoading(false)
      setShowAutoAddModal(false)
    }
    else throw new Error(t.photoformatisnotappropriate)
    }catch (error) {
      swal(
        t.error.charAt(0).toUpperCase() + t.error.slice(1),
        t.therewasissuewithfetchoperation + error.message,
        "error"
      );
      setAutofillLoading(false)
    }
  }
  const [showAutoAddModal, setShowAutoAddModal] = useState(false)
  const [dragState, setDragState] = useState(false)
  const [dropState, setDropState] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState({})
  const handleDragOpen = 
    (e) => {
      e.preventDefault()
      setDragState(true)
    }
  useEffect(() => {
    setDropState(false)
    setSelectedPhoto()
  }, [showAutoAddModal])
  const [autofillLoading, setAutofillLoading] = useState(false)
  return (
    <div className="addemployee__wrapper" onClick={e => {
      if(e.target.dataset.employeeSearch != "search"){
        setCountrySearchBoxStatePost(false)
      }
    }}>
      {isLoading && (
        <div className="fullscreen-overlay">
          <div className="spinner-container">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading...</p>
          </div>
        </div>
      )}
      {Object.keys(selectorData)?.length > 0 ? 
      <div className="passport_fill-status">
        <div className="autofill-passport-img_wrapper">
          <h4>{t.passport}</h4>
        <img onClick={() => {
          setIsFullWindowOpen(true)
          setFullwindowSelectedData(<div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img onClick={() => {
                  setIsFullWindowOpen(false)
                }}
                className="sliding img-contain"
                  src={URL.createObjectURL(selectedImage)}
                  alt="PDF or Image"
                />
              </div>)

        }} src={URL.createObjectURL(selectedImage)} alt="" />
        </div>
        <div className="add-employee-progress__wrapper">
        <h4>{t.scanneraccuracy}: <span style={{color: selectorData?.valid_score >= 30 && selectorData?.valid_score < 80 ?  "gold" : selectorData?.valid_score >= 80 ? "rgb(0,185,0)" : "red"}}>{(selectorData?.valid_score || 0) + "%"}</span></h4>
      <div>
        <div style={{width: selectorData?.valid_score + "%", background: selectorData?.valid_score >= 30 && selectorData?.valid_score < 80 ?  "gold" : selectorData?.valid_score >= 80 ? "rgb(0,185,0)" : "red", transition: "1s"}} className="employee-precide_filler">
        </div>
        </div>
      </div>
      </div> : null }
      <Form onSubmit={handleSubmit} className="mb-4">
        <div className="addemployee-input-wrapper">
        <Form.Group className="employee-fill-wrapper" controlId="citizenship_data">
          <Form.Label className="input__label" id="citizenship_data">{t.citizenship}</Form.Label>
          <Form.Control data-employee-search="search" style={{borderColor: employeeData.citizenship_data?.length == 0 && selectorData && checkState ? "red" : null}}
              type="text"
              name={"citizenship_data"}
              value={searchCountryInputPost}
              className="edit__input employee-fill-wrapper-input"
              onBlur={() => {
                setTimeout(() => {
                  setCountrySearchBoxStatePost(false)
                }, 300)
              }}
              onInput={e => {
                setSearchCountryInputPost(e.target.value)
                setCountrySearchBoxStatePost(true)
              }}
              onClick={() => {
                setCountrySearchBoxStatePost(true)
              }}
              placeholder={t.selectnationality}
            />
            {countrySearchBoxStatePost && <div data-employee-search="search" className="countries_search__wrapper">
              <div className="countries_container">
              {countriesDataPost.map((e) => 
             <div onMouseOver={() => {
              setCountryStyleIndexPost(e.id)
            }} onClick={() => {
             setSearchCountryInputPost(e["Country"+language.toUpperCase()])
             setCountrySearchBoxStatePost(false)
             }} style={countryStyleIndexPost==e.id ? {background:"purple", color: "#fff"} : null} className="country_search_card employee_add_country_search_card" key={e.id}>{e["Country"+language.toUpperCase()]}</div>
              )}
            </div>
            </div>}
          {errors.citizenship_data && (
            <Form.Control.Feedback type="invalid">
              {errors.citizenship_data}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="employee-fill-wrapper" controlId="personnel_number">
          <Form.Label className="input__label">{t.personal_number}</Form.Label>
          <Form.Control style={{borderColor: employeeData.personnel_number?.length == 0 && selectorData && checkState ? "red" : null}}
          className="employee-fill-wrapper-input"
            type="text"
            name="personnel_number"
            placeholder={t.enterNumber}
            value={employeeData.personnel_number}
            onChange={handleChange}
            isInvalid={!!errors.personnel_number}
            autoComplete="off"
          />
          {errors.personnel_number && (
            <Form.Control.Feedback type="invalid">
              {errors.personnel_number}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        </div>
        <div className="addemployee-input-wrapper">
        <Form.Group className="employee-fill-wrapper" controlId="surname_latin">
          <Form.Label className="input__label">{t.surname} (Latin)</Form.Label>
          <Form.Control style={{borderColor: employeeData.surname_latin?.length == 0 && selectorData && checkState ? "red" : null}}
          className="employee-fill-wrapper-input"
            type="text"
            name="surname_latin"
            autoComplete="off"
            placeholder={t.enterSurname}
            value={employeeData.surname_latin}
            onChange={(e) => {
              const { name, value } = e.target;
              setEmployeeData((prevState) => ({
                ...prevState,
                [name]: value,
                surname: convertToCyrillic(value), // Automatically fill Cyrillic
              }));
            }}
            isInvalid={!!errors.surname_latin}
          />
          {errors.surname_latin && (
            <Form.Control.Feedback type="invalid">
              {errors.surname_latin}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="employee-fill-wrapper" controlId="surname">
          <Form.Label className="input__label">{t.surname}</Form.Label>
          <Form.Control style={{borderColor: employeeData.surname?.length == 0 && selectorData && checkState ? "red" : null}}
          className="employee-fill-wrapper-input"
            type="text"
            name="surname"
            autoComplete="new-surname"
            value={employeeData.surname}
            onChange={handleChange} // Optional: Allow manual edits
            isInvalid={!!errors.surname}
          />
          {errors.surname && (
            <Form.Control.Feedback type="invalid">
              {errors.surname}
            </Form.Control.Feedback>
          )}
        </Form.Group>
          </div>
          <div className="addemployee-input-wrapper">
        <Form.Group className="employee-fill-wrapper" controlId="firstname_latin">
          <Form.Label className="input__label">{t.first_name} (Latin)</Form.Label>
          <Form.Control style={{borderColor: employeeData.firstname_latin?.length == 0 && selectorData && checkState ? "red" : null}}
          className="employee-fill-wrapper-input"
            type="text"
            name="firstname_latin"
            autoComplete="new-firstname"
            placeholder={t.enterFirstname}
            value={employeeData.firstname_latin}
            onChange={(e) => {
              const { name, value } = e.target;
              setEmployeeData((prevState) => ({
                ...prevState,
                [name]: value,
                firstname: convertToCyrillic(value), // Automatically fill Cyrillic
              }));
            }}
            isInvalid={!!errors.firstname_latin}
          />
          {errors.firstname_latin && (
            <Form.Control.Feedback type="invalid">
              {errors.firstname_latin}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="employee-fill-wrapper" controlId="firstname">
          <Form.Label className="input__label">{t.first_name}</Form.Label>
          <Form.Control style={{borderColor: employeeData.firstname?.length == 0 && selectorData && checkState ? "red" : null}} 
          className="employee-fill-wrapper-input"
            type="text"
            name="firstname"
            autoComplete="off"
            value={employeeData.firstname}
            onChange={handleChange} // Optional: Allow manual edits
            isInvalid={!!errors.firstname}
          />
          {errors.firstname && (
            <Form.Control.Feedback type="invalid">
              {errors.firstname}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        </div>
        <div className="addemployee-input-wrapper">
        <Form.Group className="employee-fill-wrapper" controlId="other_name_latin">
          <Form.Label className="input__label">{t.othername} (Latin)</Form.Label>
          <Form.Control style={{borderColor: employeeData.other_name_latin?.length == 0 && selectorData && checkState ? "red" : null}}
          className="employee-fill-wrapper-input"
            type="text"
            autoComplete="off"
            name="other_name_latin"
            placeholder={t.enterOthername}
            value={employeeData.other_name_latin}
            onChange={(e) => {
              const { name, value } = e.target;
              setEmployeeData((prevState) => ({
                ...prevState,
                [name]: value,
                other_name: convertToCyrillic(value), // Automatically fill Cyrillic
              }));
            }}
            isInvalid={!!errors.other_name_latin}
          />
          {errors.other_name_latin && (
            <Form.Control.Feedback type="invalid">
              {errors.other_name_latin}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="employee-fill-wrapper" controlId="other_name">
          <Form.Label className="input__label">{t.othername}</Form.Label>
          <Form.Control  style={{borderColor: employeeData.other_name?.length == 0 && selectorData && checkState ? "red" : null}}
          className="employee-fill-wrapper-input"
            type="text"
            name="other_name"
            autoComplete="off"
            value={employeeData.other_name}
            onChange={handleChange} // Optional: Allow manual edits
            isInvalid={!!errors.other_name}
          />
          {errors.other_name && (
            <Form.Control.Feedback type="invalid">
              {errors.other_name}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        </div>
        <div className="addemployee-input-wrapper">
        <Form.Group className="employee-fill-wrapper" controlId="gender">
          <Form.Label className="input__label">{t.gender}</Form.Label>
          <Form.Control style={{borderColor: employeeData.gender?.length == 0 && selectorData && checkState ? "red" : null}}
          className="employee-fill-wrapper-input"
            as="select"
            name="gender"
            value={employeeData.gender}
            onChange={handleChange}
            required
            isInvalid={!!errors.gender}
          >
            <option value="">{t.selectgender}</option>
            <option value="male">{t.male}</option>
            <option value="female">{t.female}</option>
          </Form.Control>
          {errors.gender && (
            <Form.Control.Feedback type="invalid">
              {errors.gender}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="employee-fill-wrapper" controlId="date_of_birth">
          <Form.Label className="input__label">{t.dateOfBirth}</Form.Label>
          <InputGroup style={employeeData.date_of_birth?.length == 0 && selectorData && checkState ?{border: "1px solid red"} : null}>
            <InputGroup.Text
              onClick={() =>
                dateInputRef.current && dateInputRef.current.showPicker()
              }
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-calendar"></i>
            </InputGroup.Text>
            <Form.Control
            className="employee-fill-wrapper-input iconNotShow"
              type="date"
              name="date_of_birth"
              value={employeeData.date_of_birth}
              onChange={handleChange}
              required
              isInvalid={!!errors.date_of_birth}
              ref={dateInputRef}
              style={{ appearance: "none" }}
            />
          </InputGroup>
          {errors.date_of_birth && (
            <Form.Control.Feedback type="invalid">
              {errors.date_of_birth}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        </div>
        <div className="addemployee-input-wrapper">
        <Form.Group className="employee-fill-wrapper" controlId="passportNumber">
          <Form.Label className="input__label">{t.passportNumber}</Form.Label>
          <Form.Control style={{borderColor: employeeData.passportNumber?.length == 0 && selectorData && checkState ? "red" : null}}
          className="employee-fill-wrapper-input"
            type="text"
            name="passportNumber"
            placeholder={t.enteryourpassportnumber}
            value={employeeData.passportNumber}
            onChange={handleChange}
            isInvalid={!!errors.PassportNumber}
          />
          {errors.PassportNumber && (
            <Form.Control.Feedback type="invalid">
              {errors.PassportNumber}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="employee-fill-wrapper" controlId="IssueBy">
          <Form.Label className="input__label">{t.issuedby}</Form.Label>
          <Form.Control style={{borderColor: employeeData.IssueBy?.length == 0 && selectorData && checkState ? "red" : null}}
          className="employee-fill-wrapper-input"
            type="text"
            name="IssueBy"
            placeholder={`${t.issuedby} ...`}
            value={employeeData.IssueBy}
            onChange={handleChange}
            isInvalid={!!errors.IssuedBy}
          />
          {errors.IssuedBy && (
            <Form.Control.Feedback type="invalid">
              {errors.IssuedBy}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        </div>
        <div className="addemployee-input-wrapper">
        <Form.Group className="employee-fill-wrapper" controlId="IssueDate">
          <Form.Label className="input__label">{t.issuedate}</Form.Label>
          <InputGroup style={employeeData.IssueDate?.length == 0 && selectorData && checkState ?{border: "1px solid red"} : null}>
            <InputGroup.Text
              onClick={() =>
                issueDateRef.current && issueDateRef.current.showPicker()
              }
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-calendar"></i>
            </InputGroup.Text>
            <Form.Control
            className="employee-fill-wrapper-input iconNotShow"
              type="date"
              name="IssueDate"
              value={employeeData.IssueDate}
              onChange={handleChange}
              required
              isInvalid={!!errors.IssueDate}
              ref={issueDateRef}
              style={{ appearance: "none" }} // Default kalendar ikonkasini yashirish
            />
          </InputGroup>
          {errors.IssueDate && (
            <Form.Control.Feedback type="invalid">
              {errors.IssueDate}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="employee-fill-wrapper" controlId="ValidityDate">
          <Form.Label className="input__label">{t.validityDate}</Form.Label>
          <InputGroup style={employeeData.ValidityDate?.length == 0 && selectorData && checkState ?{border: "1px solid red"} : null}>
            <InputGroup.Text
              onClick={() =>
                validityDateRef.current && validityDateRef.current.showPicker()
              }
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-calendar"></i>
            </InputGroup.Text>
            <Form.Control
            className="employee-fill-wrapper-input iconNotShow"
              type="date"
              name="ValidityDate"
              value={employeeData.ValidityDate}
              onChange={handleChange}
              required
              isInvalid={!!errors.ValidityDate}
              ref={validityDateRef}
              style={{ appearance: "none" }} // Default kalendar ikonkasini yashirish
            />
          </InputGroup>
          {errors.ValidityDate && (
            <Form.Control.Feedback type="invalid">
              {errors.ValidityDate}
            </Form.Control.Feedback>
          )}
        </Form.Group>
</div>
{Object.keys(selectorData)?.length > 0 ?
 <Form.Group controlId="CountryPersNo">
 <Form.Label className="input__label">{t.countrypersistencenumber}</Form.Label>
 <Form.Control
 style={{borderColor: employeeData.CountryPersNo?.length == 0 && selectorData && checkState ? "red" : null}}
   type="text"
   name="CountryPersNo"
   placeholder={t.entercountrypersistencenumber}
   value={employeeData.CountryPersNo}
   onChange={handleChange}
   isInvalid={!!errors.CountryPersNo}
 />
 {errors.CountryPersNo && (
   <Form.Control.Feedback type="invalid">
     {errors.CountryPersNo}
   </Form.Control.Feedback>
 )}
</Form.Group>
:
<div className="addemployee-input-wrapper">
        <Form.Group className="employee-fill-wrapper" controlId="CountryPersNo">
          <Form.Label className="input__label">{t.countrypersistencenumber}</Form.Label>
          <Form.Control className="employee-fill-wrapper-input"
          style={{borderColor: employeeData.CountryPersNo?.length == 0 && selectorData && checkState ? "red" : null}}
            type="text"
            name="CountryPersNo"
            placeholder={t.entercountrypersistencenumber}
            value={employeeData.CountryPersNo}
            onChange={handleChange}
            isInvalid={!!errors.CountryPersNo}
          />
          {errors.CountryPersNo && (
            <Form.Control.Feedback type="invalid">
              {errors.CountryPersNo}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="employee-fill-wrapper">
              <Form.Label className="input__label mt-0">{t.passportphoto}</Form.Label>
              <Form.Control
              required
                type="file"
                className="input_file_height_default"
                name="file"
                accept=".pdf, image/*"
                onChange={(e) =>
                 setSelectedFile(e.target.files[e.target.files.length - 1])
                }
                isInvalid={!!errors.file}
              />
              {errors.file && (
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              )}
            </Form.Group>
</div>}
        <div className="add__btn-wrapper">
          <Button className="add__btn mt-2 btn_height_default" type="submit" disabled={isLoading}>
            {isLoading ? "Saving..." : t.add}
          </Button>
          <Button
            className="add__btn mt-2 ml-2 btn_height_default"
            onClick={handleBack}
            disabled={isLoading}
          >
            {t.back}
          </Button>
          <Button
                    className="add__btn mt-2 ml-2 btn_height_default"
                    onClick={() => {
                    if(Object.keys(selectorData)?.length == 0) setShowAutoAddModal(true)
                    else deleteAutoFill()
                    }}
                  >
                    <i>{Object.keys(selectorData)?.length == 0 ? <RiRobot2Line /> : <TbRobotOff/>}</i> {Object.keys(selectorData)?.length == 0 ? t.autofillform : t.cancel}
                  </Button>
        </div>
      </Form>
      <Modal show={showAutoAddModal} onHide={() => setShowAutoAddModal(false)}>
      <Modal.Header closeButton>
          <Modal.Title>{t.insertyourpassportphoto}</Modal.Title>
        </Modal.Header>
      <Modal.Body>
        <div onDragStart={handleDragOpen} onDragOver={handleDragOpen} onDragLeave={(e) => {
          setDragState(false)
        }} onDrop={(e) => {
          e.preventDefault()
          const file = e.dataTransfer.files
          setSelectedPhoto(file[file.length - 1]);
          setDragState(false)
          setDropState(true)
        }} className="auto-add-employee-modal">
<i style={{color: dragState || dropState ? "dodgerblue" : null}}>{dropState ? <IoCloudDoneSharp/> :  dragState ? <FaCloudArrowUp /> : <FaCloud/>}</i>
<span>Drag & Drop File</span>
<span>Or</span>
<label style={{position: "relative"}} htmlFor="select-employee-passport">
<label htmlFor="select-employee-passport" style={{height: "40px"}} className="mt-3 pt-0 pb-0 btn_label">{t.uploadphoto}</label>
<input style={{position: "absolute", top: "0", left: "0", zIndex: -1, visibility: "hidden"}} onChange={e => {
  const { files } = e.target;
  setSelectedPhoto(files[files.length - 1]);
  setDropState(true)
}} type="file" id="select-employee-passport"/>
</label>
        </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="w-100 d-flex justify-content-end mt-2">
            <Button className="btn_height_default mr-3" variant="secondary" onClick={() => setShowAutoAddModal(false)}>
              {t.close}
            </Button>
            <Button onClick={autofillSubmit} disabled={selectedPhoto ? false : true} className="btn_height_default" variant="primary">
              {autofillLoading ? t.submiting : t.save} 
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {isFullWindowOpen && (
        <div className="overlay d-flex justify-content-center" onClick={() => {
          setIsFullWindowOpen(false)
        }}>
          <div
            className="w-75 h-100"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-btn" onClick={() => {
              setIsFullWindowOpen(false)
            }}>
              &times;
            </span>
            {fullwindowSelectedData}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddEmployee;